import { filter as filterTypes, SearchQuery, SearchQueryBuilder } from '@sprint/sprint-react-components';
import { SimpleFilterRequest, generateSimpleFilterRequestBody } from '../Components/Filters';

export default class SimpleFilterRequestBuilder {
    public static idFilter = (id: string, key: string = 'id', searchQuery?: SearchQuery): SimpleFilterRequest => {
        const orConditions: filterTypes.FilterBlock[] = [
            {
                operator: {
                    operator: 'is_any_of',
                    name: 'Is Any Of',
                    apiOperator: 'json_in',
                },
                value: [{ name: id, value: id }],
            },
        ];

        const filter: filterTypes.FieldFilter = {
            key: key,
            name: key.charAt(0).toUpperCase() + key.slice(1),
            type: filterTypes.FieldType.ENUM_ARRAY,
            orConditions: orConditions,
        };

        return {
            filterRequest: generateSimpleFilterRequestBody([{ key: 'primaryFilters', filters: [[filter]] }]),
            ...(searchQuery ?? new SearchQueryBuilder().build()).toPOJO(),
        };
    };
}
