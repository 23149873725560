import { DropDown, Modal, ModalType, OptionTypeBase } from '@sprint/sprint-react-components';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import './BulkEditSingleFieldModal.scss';
import FormBooleanEditor from './FormBooleanEditor';
import FormCheckBoxesEditor from './FormCheckBoxesEditor';
import FormDateEditor from './FormDateEditor';
import FormMultilineTextEditor from './FormMultilineTextEditor';
import FormNumberEditor from './FormNumberEditor';
import FormOwnedByEditor from './FormOwnedByEditor';
import FormRadioButtonsEditor from './FormRadioButtonsEditor';
import FormSelectEditor from './FormSelectEditor';
import FormTextEditor from './FormTextEditor';

export type Field = {
    key: string;
    name: string;
    type: FieldType;
    loadOptions?: (filter: string, loadedOptions: any, page: any) => Promise<any>;
    save: (data: Record<string, any>) => Promise<void>;
};
type FieldType =
    | 'number'
    | 'string'
    | 'text-area'
    | 'enum'
    | 'boolean'
    | 'date'
    | 'owner'
    | 'checkboxes'
    | 'radio-buttons';

export const toFieldType = (value: string): FieldType => {
    switch (value.toLowerCase()) {
        case 'text':
            return 'string';
        case 'multiline text':
            return 'text-area';
        case 'numbers':
            return 'number';
        case 'dates':
            return 'date';
        case 'checkboxes':
            return 'checkboxes';
        case 'radio buttons':
            return 'radio-buttons';
        default:
            throw new Error(value);
    }
};

interface Props {
    fields: Field[];
    close: () => void;
}

const BulkEditSingleFieldModal = (props: Props) => {
    const buildComponent = (field: Field) => {
        switch (field.type) {
            case 'string':
                return (
                    <FormTextEditor className={'selected-editor'} value={value} name={field.name} onChange={setValue} />
                );
            case 'number':
                return (
                    <FormNumberEditor
                        className={'selected-editor'}
                        value={value}
                        name={field.name}
                        onChange={setValue}
                    />
                );
            case 'enum':
                return (
                    <FormSelectEditor
                        className={'selected-editor'}
                        value={value}
                        name={field.name}
                        loadOptions={field.loadOptions!}
                        onChange={setValue}
                    />
                );
            case 'boolean':
                return (
                    <FormBooleanEditor
                        className={'selected-editor'}
                        value={value}
                        name={field.name}
                        onChange={setValue}
                    />
                );
            case 'date':
                return (
                    <FormDateEditor className={'selected-editor'} value={value} name={field.name} onChange={setValue} />
                );
            case 'owner':
                return (
                    <FormOwnedByEditor
                        className={'selected-editor'}
                        value={value}
                        name={field.name}
                        onChange={setValue}
                    />
                );
            case 'text-area':
                return (
                    <FormMultilineTextEditor
                        className={'selected-editor'}
                        value={value}
                        name={field.name}
                        onChange={setValue}
                    />
                );
            case 'checkboxes':
                return (
                    <FormCheckBoxesEditor
                        className={'selected-editor'}
                        value={value}
                        name={field.name}
                        loadOptions={field.loadOptions!}
                        onChange={setValue}
                    />
                );
            case 'radio-buttons':
                return (
                    <FormRadioButtonsEditor
                        className={'selected-editor'}
                        value={value}
                        name={field.name}
                        loadOptions={field.loadOptions!}
                        onChange={setValue}
                    />
                );
        }
    };

    const [option, setOption] = useState<OptionTypeBase>();
    const [editor, setEditor] = useState<React.JSX.Element>();
    const [reloadEditor, setReloadEditor] = useState(false);
    const [value, setValue] = useState<any>();

    useEffect(() => {
        setValue(undefined);
        option && setReloadEditor(true); // this needs to happen after value is set to undefined
    }, [option]);

    useEffect(() => {
        if (reloadEditor) {
            setEditor(buildComponent(option!.value));
            setReloadEditor(false);
        }
    }, [reloadEditor]);

    const save = async () => {
        if (option) {
            const data: Record<string, any> = {};
            data[option.value.key] = value;
            await option.value.save(data);
        }
        return Promise.resolve(true);
    };

    return (
        <Modal
            title={`Bulk Edit`}
            close={props.close}
            isOpen={true}
            type={ModalType.EDIT}
            onSubmit={save}
            className={'js-add-establishments'}
            blocking={true}
        >
            <Form.Group>
                <Form.Label className="match-padding">Select Property</Form.Label>
                <div className="form-group selected-field">
                    <DropDown
                        value={option}
                        isInvalid={false}
                        onChange={(selected) => setOption(selected)}
                        options={_.map(props.fields, (field) => {
                            return {
                                label: field.name,
                                value: field,
                            };
                        })}
                    />
                </div>
            </Form.Group>
            {editor}
        </Modal>
    );
};

export default BulkEditSingleFieldModal;
