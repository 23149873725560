import {
    AppEvent,
    EventBusInstance,
    filter as filterTypes,
    LogLevel,
    SearchQueryBuilder,
    showBanner,
    Sisp,
} from '@sprint/sprint-react-components';
import { format, parse } from 'date-fns';
import Moment from 'moment/moment';
import React, { FunctionComponent, useContext, useEffect, useState } from 'react';
import { Badge, Button, Spinner } from 'react-bootstrap';
import { ModulePermissions } from '../../../EducationDataGrid/models/Enums';
import { DealLinksRequest } from '../../Api/DealLinksRequest';
import SimpleGetRepository from '../../Api/SimpleGetRepository';
import { customPropertyDataTypeMap } from '../../HelperFunctions/CustomPropertiesFiltersAndColumnsBuilder';
import { UserPermissionsContext } from '../../index';
import CustomPropertyFilterType from '../../Models/CustomPropertyFilterType';
import {
    CloseByDetails,
    CreatedByDetails,
    Deal,
    FollowUpDetails,
    ModifiedByDetails,
    QuoteCountMeta,
    Relationship,
    SaleCountMeta,
    TaskCountMeta,
    Type,
} from '../../Models/Deal';
import { DealPipeline } from '../../Models/DealPipeline';
import { UniqueKeyType } from '../../Models/Enums';
import UserType from '../../Models/UserType';
import './DealsSisp.scss';

interface Props {
    uniqueKey: string;
    onSuccess: (results: any) => Promise<boolean>;
}

const DealsPreviewSisp: FunctionComponent<Props> = (props: Props) => {
    const contactUrlPrefix = 'subscribers/contacts/view/';
    const organisationUrlPrefix = 'subscribers/organisations/view/';

    const userPermissions = useContext(UserPermissionsContext);

    const dealLinksRepository = new SimpleGetRepository('', new DealLinksRequest().Url);

    const customProperties: any = JSON.parse(
        String((document.getElementById('custom-properties') as HTMLInputElement).value),
    );

    const [shown, setShown] = useState<boolean>(false);

    const [id, setId] = useState<number>();
    const [name, setName] = useState<string>();
    const [value, setValue] = useState<string>();
    const [type, setType] = useState<Type>();
    const [pipeline, setPipeline] = useState<DealPipeline>();
    const [stage, setStage] = useState<string>();
    const [ownedBy, setOwnedBy] = useState<UserType>();
    const [description, setDescription] = useState<string>();
    const [followUpDetails, setFollowUpDetails] = useState<FollowUpDetails>();
    const [closeByDetails, setCloseByDetails] = useState<CloseByDetails>();
    const [relatesToContact, setRelatesToContact] = useState<Relationship>();
    const [relatesToContactUrl, setRelatesToContactUrl] = useState<string>();
    const [relatesToOrganisation, setRelatesToOrganisation] = useState<Relationship>();
    const [relatesToOrganisationUrl, setRelatesToOrganisationUrl] = useState<string>();
    const [customPropertyValues, setCustomPropertyValues] = useState<{ [key: string]: string }>();

    const [linksLoading, setLinksLoading] = useState<boolean>(false);
    const [quoteMeta, setQuoteMeta] = useState<QuoteCountMeta>({ count: 0, quotes: [] });
    const [saleMeta, setSaleMeta] = useState<SaleCountMeta>({ count: 0, sales: [] });
    const [taskMeta, setTaskMeta] = useState<TaskCountMeta>({ count: 0, tasks: [] });

    const [createdBy, setCreatedBy] = useState<CreatedByDetails>();
    const [modifiedBy, setModifiedBy] = useState<ModifiedByDetails>();

    const [editMessage, setEditMessage] = useState<{ id: number }>();

    useEffect(() => {
        EventBusInstance.subscribe('show-hoverover-component', (event: AppEvent<Deal>) => {
            if (event.target !== props.uniqueKey) {
                // Close sisp if another one has been opened
                setShown(false);
                return;
            }

            setEditMessage({
                id: event.message.id!,
            });

            // Set up preview data
            setId(event.message.id);
            setName(event.message.name);
            setValue(event.message.value.formatted);
            setType(event.message.type);
            setPipeline(event.message.pipeline);
            setStage(event.message.stage?.name);
            setOwnedBy(event.message.owned_by);
            setDescription(event.message.description);
            setFollowUpDetails(event.message.follow_up_details);
            setCloseByDetails(event.message.close_by_details);

            if (event.message.relates_to_contact) {
                setRelatesToContact(event.message.relates_to_contact);
                setRelatesToContactUrl(`${contactUrlPrefix}${event.message.relates_to_contact.id}`);
            }
            if (event.message.relates_to_organisation) {
                setRelatesToOrganisation(event.message.relates_to_organisation);
                setRelatesToOrganisationUrl(`${organisationUrlPrefix}${event.message.relates_to_organisation.id}`);
            }

            setCustomPropertyValues(event.message.custom_properties);
            setCreatedBy(event.message.created_by);
            setModifiedBy(event.message.modified_by);
            setShown(true);
        });

        EventBusInstance.subscribe('show-hoverover-component', (event: AppEvent<boolean>) => {
            if (event.target !== 'deals-data-grid-board-preview-close') return;
            setShown(false);
        });
    }, [shown]);

    useEffect(() => {
        if (id) {
            getDealLinks(id);
        }
    }, [id]);

    const relativeDate = (date: string): string => {
        return Moment(parse(date, 'do, LLL yyyy', new Date())).fromNow();
    };

    const relativeDateTime = (date: string): string => {
        return Moment(parse(date, 'do, LLL yyyy h:mmaaaa', new Date())).fromNow();
    };

    const getDealLinks = (id?: number) => {
        setLinksLoading(true);
        const query = new SearchQueryBuilder().build();

        dealLinksRepository
            .get(query, id)
            .then((result) => {
                setQuoteMeta(result['quote_meta'] as QuoteCountMeta);
                setSaleMeta(result['sale_meta'] as SaleCountMeta);
                setTaskMeta(result['task_meta'] as TaskCountMeta);
                setLinksLoading(false);
            })
            .catch((err) => {
                setLinksLoading(false);
                showBanner({
                    message: 'Failed to load CRM links for preview - ' + (err?.message ?? err),
                    level: LogLevel.ERROR,
                });
            });
    };

    const getCustomPropertyDetails = (id: string): CustomPropertyFilterType | null => {
        const customPropertyDetails = customProperties.filter(
            (customProperty: CustomPropertyFilterType) => customProperty.key.split('-')[1] === id,
        );
        if (customPropertyDetails.length < 1) {
            showBanner({
                message: 'Failed to find Custom Property',
                level: LogLevel.ERROR,
                dismissable: false,
            });
            return null;
        }
        return customPropertyDetails[0];
    };

    const formatCustomPropertyValues = (id: string, value: string, data_type: string): any => {
        switch (customPropertyDataTypeMap.get(data_type)) {
            case filterTypes.FieldType.ENUM_ARRAY:
                const badges = value.split(/\r?\n/).map((item: any, i: number) => {
                    return (
                        <Badge style={{ marginRight: '3px' }} key={`custom-${id}-badge-${i}`}>
                            {item}
                        </Badge>
                    );
                });
                return <p className="side-panel">{badges}</p>;
            case filterTypes.FieldType.DATE:
                const date = new Date(value);
                return <p className="side-panel">{format(date, 'do, LLL yyyy')}</p>;
            default:
                return <p className="side-panel">{value}</p>;
        }
    };

    return (
        <Sisp
            isOpen={shown}
            onCancel={() => {
                setShown(false);
            }}
            footerOverride={
                <>
                    <Button
                        onClick={() => {
                            window.location.href = 'deals/view/' + id;
                        }}
                    >
                        View
                    </Button>
                    <Button
                        variant="default"
                        onClick={() => {
                            EventBusInstance.publish({
                                topic: 'show-hoverover-component',
                                message: editMessage,
                                target: props.uniqueKey.replace(
                                    `-${UniqueKeyType.PREVIEW_SISP}`,
                                    `-${UniqueKeyType.EDIT_SISP}`,
                                ),
                            });
                        }}
                    >
                        Edit
                    </Button>
                    <Button
                        variant="default"
                        onClick={() => {
                            setShown(false);
                        }}
                    >
                        Cancel
                    </Button>
                </>
            }
        >
            <h4>{name}</h4>
            {modifiedBy && (
                <small>
                    {modifiedBy.modified_by.name!} modified {relativeDate(modifiedBy.date)}
                </small>
            )}

            {value && (
                <div className="separator">
                    <p className="side-panel-small">Value</p>
                    <p className="side-panel">{value}</p>
                </div>
            )}

            {relatesToContact && (
                <div className="separator">
                    <p className="side-panel-small">Relates To Contact</p>
                    <p className="side-panel">
                        <a href={relatesToContactUrl}>{relatesToContact.name}</a>
                    </p>
                </div>
            )}

            {relatesToOrganisation && (
                <div className="separator">
                    <p className="side-panel-small">Relates To Organisation</p>
                    <p className="side-panel">
                        <a href={relatesToOrganisationUrl}>{relatesToOrganisation.name}</a>
                    </p>
                </div>
            )}

            {!relatesToContact && !relatesToOrganisation && (
                <div className="separator">
                    <p className="side-panel-small">Relates To </p>
                    <p className="side-panel">No Relations</p>
                </div>
            )}

            {type && (
                <div className="separator">
                    <p className="side-panel-small">Type</p>
                    <p className="side-panel">{type.type}</p>
                </div>
            )}

            {pipeline && (
                <div className="separator">
                    <p className="side-panel-small">Pipeline</p>
                    <p className="side-panel">{pipeline.name}</p>
                </div>
            )}

            {stage && (
                <div className="separator">
                    <p className="side-panel-small">Stage</p>
                    <p className="side-panel">{stage}</p>
                </div>
            )}

            {ownedBy && (
                <div className="separator">
                    <p className="side-panel-small">Owned By</p>
                    <p className="side-panel">
                        <span style={{ display: 'flex' }}>
                            <img
                                src={ownedBy.avatar!}
                                className={'img-rounded'}
                                style={{ height: '20px', marginRight: '8px' }}
                                alt={ownedBy.name!}
                            />
                            <span>{ownedBy.name!}</span>
                        </span>
                    </p>
                </div>
            )}

            {followUpDetails && (
                <div className="separator">
                    <p className="side-panel-small">Follow Up</p>
                    <p className="side-panel">
                        {followUpDetails.follow_up_date ? (
                            <span style={{ color: followUpDetails.is_overdue ? 'red' : '#333' }}>
                                {followUpDetails.follow_up_date} - {relativeDate(followUpDetails.follow_up_date)}
                            </span>
                        ) : (
                            'No Follow Up Date'
                        )}
                    </p>
                </div>
            )}

            {closeByDetails && (
                <div className="separator">
                    <p className="side-panel-small">Closed Date</p>
                    <p className="side-panel">
                        {closeByDetails.close_date ? (
                            <span style={{ color: closeByDetails.is_overdue ? 'red' : '#333' }}>
                                {closeByDetails.close_date} - {relativeDate(closeByDetails.close_date)}
                            </span>
                        ) : (
                            'No Close By Date'
                        )}
                    </p>
                </div>
            )}

            {description && (
                <div className="separator">
                    <p className="side-panel-small">Description</p>
                    <p className="side-panel">{description}</p>
                </div>
            )}

            <div className="separator">
                <p className="side-panel-small">Tasks ({taskMeta?.count})</p>
                {linksLoading && <Spinner style={{ width: '1em', height: '1em' }} animation="border" role="status" />}
                {!linksLoading && taskMeta.count == 0 && <p className="side-panel">No tasks yet</p>}
                {!linksLoading && taskMeta.count > 0 && (
                    <span style={{ color: taskMeta.tasks[0].due_date.is_overdue ? 'red' : '#333' }}>
                        {taskMeta.tasks[0].due_date.is_overdue ? 'Overdue: ' : 'Upcoming: '}
                        {taskMeta.tasks[0].type} - {relativeDateTime(taskMeta.tasks[0].due_date.date)}
                    </span>
                )}
            </div>

            <div className="separator">
                <p className="side-panel-small">Quotes ({quoteMeta?.count})</p>
                {linksLoading && <Spinner style={{ width: '1em', height: '1em' }} animation="border" role="status" />}
                {!linksLoading && quoteMeta.count == 0 && <p className="side-panel">No quotes yet</p>}
                {!linksLoading && quoteMeta.count > 0 && (
                    <p className="side-panel">
                        Last: {quoteMeta.quotes[0].net_total} on {quoteMeta.quotes[0].date}
                    </p>
                )}
            </div>

            <div className="separator">
                <p className="side-panel-small">Sales ({saleMeta?.count})</p>
                {linksLoading && <Spinner style={{ width: '1em', height: '1em' }} animation="border" role="status" />}
                {!linksLoading && saleMeta.count == 0 && <p className="side-panel">No sales yet</p>}
                {!linksLoading && saleMeta.count > 0 && (
                    <p className="side-panel">
                        Last: {saleMeta.sales[0].net_total} on {saleMeta.sales[0].date}
                    </p>
                )}
            </div>
            {userPermissions.customPropertiesDeals === ModulePermissions.ENABLED &&
                customPropertyValues &&
                Object.entries(customPropertyValues).map(([key, value]) => {
                    const customPropertyDetails = getCustomPropertyDetails(key);
                    if (customPropertyDetails) {
                        return (
                            <div className="separator" key={`custom-${key}`}>
                                <p className="side-panel-small">{customPropertyDetails.name}</p>
                                {formatCustomPropertyValues(key, value, customPropertyDetails.data_type)}
                            </div>
                        );
                    }
                })}

            {createdBy && (
                <div className="separator">
                    <p className="side-panel-small">Created By</p>
                    <p className="side-panel">
                        <span style={{ display: 'flex' }}>
                            <img
                                src={createdBy.created_by.avatar!}
                                className={'img-rounded'}
                                style={{ height: '20px', marginRight: '8px' }}
                                alt={createdBy.created_by.name!}
                            />
                            <span>
                                {createdBy.created_by.name!}, on {createdBy.date}
                            </span>
                        </span>
                    </p>
                </div>
            )}
        </Sisp>
    );
};

export default DealsPreviewSisp;
