import React, { ReactNode } from 'react';
import { Form } from 'react-bootstrap';
import OwnedByDropdown from '../../Applications/DataGrids/Grids/CRM/OwnedByDropdown';
import UserType from '../../Applications/DataGrids/Models/UserType';

interface Props {
    name: ReactNode;
    value: UserType | null;
    onChange: (newValue: UserType | null) => void;
    className?: string;
}

const FormOwnedByEditor = (props: Props) => {
    const changeValue = (option: any) => {
        const newValue = option ? (option.raw as UserType) : null;
        props.onChange(newValue);
    };

    return (
        <Form.Group className={props.className}>
            <Form.Label className="match-padding">Owner</Form.Label>
            <OwnedByDropdown onChange={changeValue} label={''} defaultToYou={false} isClearable={false} />
        </Form.Group>
    );
};

export default FormOwnedByEditor;
