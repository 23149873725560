import _ from 'lodash';
import BulkUpdateRepository, { UpdateType } from '../../Applications/DataGrids/Api/BulkEditRepository';
import CustomProperty from '../../Applications/DataGrids/Models/CustomProperty';
import { Field, toFieldType } from './BulkEditSingleFieldModal';

export const toFields = (
    type: UpdateType,
    customProperties: CustomProperty[],
    bulkUpdateRepository: BulkUpdateRepository,
    ids: number[],
) =>
    _.map(customProperties, (property) => {
        const field: Field = {
            key: property.name,
            name: property.name,
            type: toFieldType(property.data_type),
            save: async (data: Record<string, any>) => {
                const key = Object.keys(data)[0];
                const value =
                    field.type === 'checkboxes' ? _.map(data[key], (item) => item.value).join('\r\n') : data[key];

                await bulkUpdateRepository.update({ customProperty: true, type, field: key, value, ids });
            },
        };

        if (['radio-buttons', 'checkboxes'].includes(field.type)) {
            const values = property.options ? property.options.split(/\r?\n/) : [];
            const options = values.map((value: any) => {
                if (value.trim()) {
                    return {
                        value: value,
                        label: value,
                    };
                }
            });

            field.loadOptions = async (filter: string, _loadedOptions, { page }) => {
                return {
                    options: options,
                    hasMore: false,
                    additional: {
                        page: page + 1,
                    },
                };
            };
        }

        return field;
    });
