import { PendingButton, Sisp } from '@sprint/sprint-react-components';
import React, { FunctionComponent, useState } from 'react';
import { Button } from 'react-bootstrap';
import { ClientGroupType } from '../../Models/Enums';
import ClientGroupsForm from './ClientGroupsForm';

interface Props {
    shown: boolean;
    onClose: () => void;
    onSuccess: (event: any) => Promise<boolean>;
    clientGroupType: ClientGroupType;
    dataGridUniqueKey: string;
    entitySingular: string;
    formId: string;
}

const ClientGroupsAddSisp: FunctionComponent<Props> = (props: Props) => {
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    return (
        <Sisp
            isOpen={props.shown}
            onCancel={() => {
                props.onClose();
            }}
            footerOverride={
                <>
                    <Button
                        onClick={(e) => {
                            e.preventDefault();
                            props.onClose();
                        }}
                        variant="secondary"
                        form={props.formId}
                    >
                        Cancel
                    </Button>
                    <PendingButton pending={isSubmitting} type="submit" form={props.formId}>
                        Save
                    </PendingButton>
                </>
            }
        >
            <h4>Add a {props.entitySingular}</h4>
            <ClientGroupsForm
                {...props}
                onSubmit={(data: any) => {
                    props.onSuccess(data);
                    props.onClose();
                }}
                setIsSubmitting={(submitting: boolean) => setIsSubmitting(submitting)}
            />
        </Sisp>
    );
};

export default ClientGroupsAddSisp;
