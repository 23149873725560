import React, { ReactNode, useState } from 'react';
import { Form } from 'react-bootstrap';

interface Props {
    value: string | null;
    name: ReactNode;
    onChange: (newValue: string | null) => void;
    className?: string;
}

const FormMultilineTextEditor = (props: Props): JSX.Element => {
    const [value, setValue] = useState(props.value ?? '');

    const changeValue = () => {
        props.onChange(value);
    };

    return (
        <Form.Group className={props.className}>
            <Form.Label className="match-padding">{props.name}</Form.Label>
            <Form.Control
                value={value}
                type="text"
                as="textarea"
                rows={8}
                isInvalid={false}
                onChange={(event) => setValue(event.target.value)}
                onBlur={changeValue}
            />
        </Form.Group>
    );
};

export default FormMultilineTextEditor;
