import {
    AsyncDropDownPaginated,
    OptionTypeBase,
    OptionTypeBaseUserFormatter,
    SearchQuery,
    showBanner,
    SortOrder,
} from '@sprint/sprint-react-components';
import _ from 'lodash';
import React, { FunctionComponent, useContext, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { RepositoryFactoryContext, UserContext } from '../..';
import { UserTypeRequest } from '../../Api/UserTypeRequest';
import UserType from '../../Models/UserType';

interface Props {
    onChange: (option: OptionTypeBase | null | undefined) => void;
    label?: string;
    defaultToYou?: boolean;
    isClearable?: boolean;
}

const OwnedByDropdown: FunctionComponent<Props> = (props: Props) => {
    const userRepository = useContext(RepositoryFactoryContext).getApiRepository(new UserTypeRequest());
    const userContext = useContext(UserContext);

    const [ownedBy, setOwnedBy] = useState<OptionTypeBase | null | undefined>();

    const preload = async () => {
        const users = await loadUsers('', 1);
        const matchedUser = _.find(users, (user) => Number(user.value) === Number(userContext.id));
        setOwnedBy(matchedUser);
    };

    useEffect(() => {
        if (props.defaultToYou) {
            preload();
        }
    }, []);

    useEffect(() => {
        props.onChange(ownedBy);
    }, [ownedBy]);

    const loadUsers = (filter?: string, page?: number): Promise<{ value: number; label: JSX.Element }[]> => {
        // Fetch all users for client account
        const query = new SearchQuery(page ?? 1, 100, 'id', SortOrder.ASC, filter);
        return userRepository
            .search(query)
            .then((results) => {
                return _.map(
                    _.filter(results.results as UserType[], (user: UserType) => {
                        return _.includes(user?.name?.toLowerCase(), filter?.toLowerCase());
                    }),
                    (user: UserType) => {
                        const formatted = OptionTypeBaseUserFormatter(user);
                        (formatted as any).raw = user;
                        return formatted;
                    },
                );
            })
            .catch((err: any) => {
                showBanner({
                    message: 'Failed to get users - ' + (err?.message ?? err),
                });
                return [];
            });
    };

    return (
        <>
            {props.label && <Form.Label>{props.label}</Form.Label>}
            <AsyncDropDownPaginated
                id={'owned_by_id'}
                value={ownedBy}
                isInvalid={false}
                isClearable={props.isClearable ?? true}
                menuPlacement="auto"
                menuPosition="fixed"
                menuPortalTarget={document.body}
                classNamePrefix={'owned-by-dropdown'}
                onChange={(selected: OptionTypeBase) => {
                    setOwnedBy(selected);
                }}
                loadOptions={async (filter: string, _loadedOptions, { page }) => {
                    const res = await loadUsers(filter, page);
                    return {
                        options: res,
                        hasMore: false,
                        additional: {
                            page: page + 1,
                        },
                    };
                }}
            />
        </>
    );
};

export default OwnedByDropdown;
