import React, { ReactNode } from 'react';
import { Form } from 'react-bootstrap';

interface Props {
    name: ReactNode;
    value?: boolean;
    onChange: (newValue: boolean) => void;
    className?: string;
}

const FormBooleanEditor = (props: Props) => {
    const changeValue = () => {
        const newValue = !props.value;
        props.onChange(newValue);
    };

    return (
        <Form.Group className={props.className}>
            <Form.Label className="match-padding">{props.name}</Form.Label>
            <Form.Control
                type="checkbox"
                style={{ width: '1.4em' }}
                checked={props.value}
                onChange={changeValue}
            ></Form.Control>
        </Form.Group>
    );
};

export default FormBooleanEditor;
