import React, { ReactNode, useState } from 'react';
import { Form } from 'react-bootstrap';

interface Props {
    value: number | null;
    name: ReactNode;
    onChange: (newValue: number | undefined) => void;
    className?: string;
}

const FormNumberEditor = (props: Props) => {
    const [value, setValue] = useState<string>(props.value?.toString() ?? '');

    const changeValue = () => {
        props.onChange(value === '' ? undefined : parseInt(value));
    };

    return (
        <Form.Group className={props.className}>
            <Form.Label className="match-padding">{props.name}</Form.Label>
            <Form.Control
                value={value}
                type="number"
                isInvalid={false}
                onChange={(event) => setValue(event.target.value)}
                onBlur={changeValue}
            />
        </Form.Group>
    );
};

export default FormNumberEditor;
