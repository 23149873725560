import { ExtendedColumn, HoveroverButton, SortOrder } from '@sprint/sprint-react-components';
import React, { FunctionComponent, useContext } from 'react';
import { ClientGroupType } from '../../Models/Enums';
import { KnowledgeBaseUrlKey, KnowledgeBaseUrls } from '../../HelperFunctions/KnowledgeBaseUrls';
import UniqueKeyBuilder from '../../HelperFunctions/UniqueKeyBuilder';
import { RepositoryFactoryContext } from '../../index';
import { ClientGroupsRequest } from '../../Api/ClientGroupsRequest';
import ClientGroup from '../../Models/ClientGroup';
import { UniqueKeyType } from '../../Models/Enums';
import CampusDataGrid, {
    ActionBarMeta,
    AddSispMeta,
    DeleteModalMeta,
    EditSispMeta,
    DataGridMeta,
    PromptMeta,
} from '../../Grids/CampusDataGrid';
import ClientGroupsAddSisp from './ClientGroupsAddSisp';
import ClientGroupsEditSisp from './ClientGroupsEditSisp';
import ClientGroupsDeleteModal from './ClientGroupsDeleteModal';

interface Props {
    clientGroupType: ClientGroupType;
    dataGridUniqueKey: string;
    mainDataGridEntitySingular: string;
    mainDataGridEntityPlural: string;
    addSisp?: React.FunctionComponent<any>;
    editSisp?: React.FunctionComponent<any>;
    deleteModal?: React.FunctionComponent<any>;
    mainTabLabel?: string;
    searchFilterPlaceholder?: string;
    dataGridEntitySingular?: string;
    dataGridEntityPlural?: string;
}

enum ClientGroupsColumnKey {
    ID,
    NAME,
    NUMBER_OF_ENTITIES,
}

const ClientGroupsTable: FunctionComponent<Props> = (props: Props) => {
    const editSispUniqueKey = UniqueKeyBuilder.make(props.dataGridUniqueKey, UniqueKeyType.EDIT_SISP);

    const genericGroupsRepository = useContext(RepositoryFactoryContext).getApiRepository(
        new ClientGroupsRequest(props.clientGroupType),
    );

    const Columns: Record<ClientGroupsColumnKey, ExtendedColumn> = {
        [ClientGroupsColumnKey.ID]: {
            key: 'id',
            name: 'ID',
        },
        [ClientGroupsColumnKey.NAME]: {
            key: 'name',
            name: 'Name',
            sortable: true,
            width: '1fr',
            renderCell: (fprops) => {
                const name = (fprops.row as ClientGroup).name;
                return (
                    <>
                        {name}
                        <HoveroverButton
                            contents="Edit"
                            eventBusMessageTarget={editSispUniqueKey}
                            eventBusMessage={fprops.row as ClientGroup}
                        />
                    </>
                );
            },
        },
        [ClientGroupsColumnKey.NUMBER_OF_ENTITIES]: {
            key: 'number_of_entities',
            name: `Number of ${props.mainTabLabel}`,
            sortable: true,
            width: '1fr',
        },
    };

    const DefaultColumns: ExtendedColumn[] = [
        Columns[ClientGroupsColumnKey.NAME],
        Columns[ClientGroupsColumnKey.NUMBER_OF_ENTITIES],
    ];

    const entitySingular = props.dataGridEntitySingular ?? `${props.mainDataGridEntitySingular} Group`;

    const dataGridMeta: DataGridMeta = {
        uniqueKey: props.dataGridUniqueKey,
        entitySingular: entitySingular,
        entityPlural: props.dataGridEntityPlural ?? `${props.mainDataGridEntitySingular} Groups`,
        columnOptions: Columns,
        defaultColumns: DefaultColumns,
        defaultSortColumn: 'name',
        defaultSortDirection: SortOrder.ASC,
    };

    const actionBarMeta: ActionBarMeta = {
        searchPlaceHolder: props.searchFilterPlaceholder ?? `Search ${props.mainDataGridEntitySingular} ${props.dataGridEntityPlural ?? 'Groups'} name`,
        includeCounts: true,
    };

    const sispsAdditionalProps = {
        clientGroupType: props.clientGroupType,
        dataGridUniqueKey: props.dataGridUniqueKey,
        entitySingular: entitySingular,
        formId: `${props.dataGridUniqueKey}_add_edit_form`,
    };

    const addSispMeta: AddSispMeta = {
        key: UniqueKeyBuilder.make(props.dataGridUniqueKey, UniqueKeyType.ADD_SISP),
        sisp: props.addSisp ?? ClientGroupsAddSisp,
        additionalProps: sispsAdditionalProps,
    };

    const ediSispMeta: EditSispMeta = {
        sisp: props.editSisp ?? ClientGroupsEditSisp,
        additionalProps: sispsAdditionalProps,
    };

    const deleteModalMeta: DeleteModalMeta = {
        modal: props.deleteModal ?? ClientGroupsDeleteModal,
        additionalProps: {
            clientGroupType: props.clientGroupType,
            mainEntityPlural: props.mainDataGridEntityPlural,
            clientGroupEntity: props.dataGridEntitySingular?.toLowerCase() ?? 'group',
        },
    };

    const promptMeta: PromptMeta = {
        // TODO CD CC-7419: Need an icon - ask Guy
        icon: '',
        iconHeight: 80,
        // TODO CD CC-7419: Need a knowledge base url - ask Guy
        // helpCentreLink: KnowledgeBaseUrls.get(KnowledgeBaseUrlKey.CREATE_CUSTOM_PROPERTY),
    };

    return (
        <>
            <CampusDataGrid
                repository={genericGroupsRepository}
                actionBarMeta={actionBarMeta}
                addSispMeta={addSispMeta}
                editSispMeta={ediSispMeta}
                deleteModalMeta={deleteModalMeta}
                dataGridMeta={dataGridMeta}
                promptMeta={promptMeta}
            />
        </>
    );
};

export default ClientGroupsTable;
