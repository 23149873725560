import React, { ReactNode, useState } from 'react';
import { Form } from 'react-bootstrap';

interface Props {
    value: Date | null;
    name: ReactNode;
    includeTime?: boolean;
    onChange: (newValue: string | null) => void;
    className?: string;
}

const FormDateEditor = (props: Props) => {
    const fromDbDate = (value: Date | null | undefined) =>
        value ? new Date(value).toISOString().split('T')[0] : undefined;

    const [value, setValue] = useState<string | undefined>(fromDbDate(props.value));

    const changeValue = () => {
        /* prettier-ignore */
        const newValue = !value
            ? null
            : props.includeTime
                ? new Date(value).toISOString()
                : new Date(value).toISOString().split('T')[0];
        props.onChange(newValue);
    };

    return (
        <Form.Group className={props.className}>
            <Form.Label className="match-padding">{props.name}</Form.Label>
            <Form.Control
                value={value}
                type="date"
                isInvalid={false}
                onChange={(event) => setValue(event.target.value)}
                onBlur={changeValue}
            />
        </Form.Group>
    );
};

export default FormDateEditor;
