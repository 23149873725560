import { AsyncDropDownPaginated } from '@sprint/sprint-react-components';
import React, { ReactNode } from 'react';
import { Form } from 'react-bootstrap';

interface Props {
    value: {
        value: any;
        label: string;
    } | null; // it's one of the enum values
    name: ReactNode;
    loadOptions: (filter: string, loadedOptions: any, page: any) => any;
    onChange: (newValue: string | null) => void;
    className?: string;
}

const FormSelectEditor = (props: Props) => {
    const changeValue = (option: any) => {
        const newValue = option ? option : null;
        props.onChange(newValue);
    };

    return (
        <Form.Group className={props.className}>
            <Form.Label className="match-padding">{props.name}</Form.Label>
            <AsyncDropDownPaginated
                id="form-select-editor"
                value={props.value}
                isInvalid={false}
                isClearable={true}
                menuPlacement="top"
                menuPosition="fixed"
                menuPortalTarget={document.body}
                onChange={changeValue}
                loadOptions={props.loadOptions}
            />
        </Form.Group>
    );
};

export default FormSelectEditor;
