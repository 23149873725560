import CSS from "csstype";
import React, { FunctionComponent } from "react";
import './StyledPill.scss';

interface Props {
    cellContent?: string;
    style?: CSS.Properties;
}

const StyledPill: FunctionComponent<Props> = (props: Props) => {
    return (
        <>
            <p>
                <span className="styled-pill" style={props.style}>{props.cellContent}</span>
            </p>
       </>
    );
};

StyledPill.defaultProps = {
    cellContent: '',
};

export default StyledPill;