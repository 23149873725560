import _ from 'lodash';
import React, { ReactNode, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';

interface Props {
    value: {
        value: any;
        label: string;
    } | null; // it's one of the enum values
    name: ReactNode;
    loadOptions: (filter: string, loadedOptions: any, page: any) => any;
    onChange: (newValue: string | null) => void;
    className?: string;
}

const FormRadioButtonsEditor = (props: Props): JSX.Element => {
    const [options, setOptions] = useState<[]>([]);
    const changeValue = (option: any) => {
        const newValue = option ? option : null;
        props.onChange(newValue);
    };

    useEffect(() => {
        props.loadOptions('', {}, 1).then((result: any) => setOptions(result.options));
    }, []);

    return (
        <Form.Group className={props.className}>
            <Form.Label className="match-padding">{props.name}</Form.Label>
            {options.length &&
                _.map(options, (option: any, i: number) => {
                    return (
                        <Form.Check
                            custom={true}
                            data-cy={`custom-radio-option-${i}`}
                            value={option.value}
                            name={'name'}
                            label={option.value}
                            id={option.value}
                            type={'radio'}
                            onChange={() => changeValue(option.value)}
                        />
                    );
                })}
        </Form.Group>
    );
};

export default FormRadioButtonsEditor;
