import { Deal, DealEditState } from '../../Models/Deal';
import { parse } from 'date-fns';

export const convertDealToEditState = (deal: Deal): DealEditState => {
    const id = deal.id!;
    const name = deal.name;

    const value = deal.value.formatted;
    const deal_type_id = deal.type.id;
    const pipeline_id = deal.pipeline ? deal.pipeline.id : 0;
    const stage = deal.stage ? deal.stage.name : '';
    const stage_id = deal.stage ? deal.stage.id : 0;
    const owned_by_id = deal.owned_by?.id;
    const follow_up_string = deal.follow_up_details.follow_up_date;
    const close_by_string = deal.close_by_details.close_date;

    const follow_up_date =
        follow_up_string.length > 0 ? parse(follow_up_string, 'do, LLL yyyy', new Date()) : undefined;
    const close_by_date = close_by_string.length > 0 ? parse(close_by_string, 'do, LLL yyyy', new Date()) : undefined;

    const description = deal.description;
    const custom_properties = deal.custom_properties;

    return {
        id: id,
        name: name,
        value: value,
        deal_type_id: deal_type_id,
        pipeline_id: pipeline_id,
        stage: stage,
        stage_id: stage_id,
        owned_by_id: owned_by_id,
        follow_up_date: follow_up_date,
        close_by_date: close_by_date,
        relates_to_contact: deal.relates_to_contact ? deal.relates_to_contact.id : undefined,
        relates_to_organisation: deal.relates_to_organisation ? deal.relates_to_organisation.id : undefined,
        description: description,
        custom_properties: custom_properties,
    };
};
