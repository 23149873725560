import { AppEvent, EventBusInstance, PendingButton, Sisp } from '@sprint/sprint-react-components';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { ClientGroupType } from '../../Models/Enums';
import ClientGroup from '../../Models/ClientGroup';
import ClientGroupsForm from './ClientGroupsForm';

interface Props {
    uniqueKey: string;
    onSuccess: (event: any) => Promise<boolean>;
    clientGroupType: ClientGroupType;
    dataGridUniqueKey: string;
    entitySingular: string;
    formId: string;
}

const ClientGroupsEditsSisp: FunctionComponent<Props> = (props: Props) => {
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const [shown, setShown] = useState<boolean>(false);
    const [message, setMessage] = useState<ClientGroup>();

    useEffect(() => {
        EventBusInstance.subscribe('show-hoverover-component', (event: AppEvent<ClientGroup>) => {
            if (event.target !== props.uniqueKey) return;
            setMessage(event.message);
            setShown(true);
        });
    }, [shown]);

    return (
        <Sisp
            isOpen={shown}
            onCancel={() => {
                setShown(false);
            }}
            footerOverride={
                <>
                    <Button
                        onClick={(e) => {
                            e.preventDefault();
                            setShown(false);
                        }}
                        variant="secondary"
                        form={props.formId}
                    >
                        Cancel
                    </Button>
                    <PendingButton pending={isSubmitting} type="submit" form={props.formId}>
                        Save
                    </PendingButton>
                </>
            }
        >
            <h4>Update {props.entitySingular}</h4>
            <ClientGroupsForm
                {...props}
                onSubmit={(data: any) => {
                    props.onSuccess(data);
                    setShown(false);
                }}
                shown={shown}
                setIsSubmitting={(submitting: boolean) => setIsSubmitting(submitting)}
                clientGroup={message}
            />
        </Sisp>
    );
};

export default ClientGroupsEditsSisp;
