import {
    EntityTarget,
    ExtendedColumn,
    filter as filterTypes,
    HoveroverButton,
    OptionTypeBase,
    SearchQuery,
    SortOrder,
    UserFormatter,
} from '@sprint/sprint-react-components';
import _ from 'lodash';
import React, { FunctionComponent, useContext, useEffect, useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Provider } from 'react-redux';
import { Options } from 'react-select';
import { PersistGate } from 'redux-persist/integration/react';
import { DictionaryContext, RepositoryFactoryContext, UserPermissionsContext } from '../..';
import { ModulePermissions } from '../../../EducationDataGrid/models/Enums';
import MultiViewSwitcher from '../../../../CommonComponents/MultiViewSwitcher/MultiViewSwitcher';
import { ucwords } from '../../../../Helpers/StringHelper';
import { configureStore } from '../../../Deals/configureStore';
import { DEFAULT_COLUMN_WIDTH } from '../../../EducationDataGrid/constant';
import { buildCustomPropertiesFiltersAndColumns } from '../../HelperFunctions/CustomPropertiesFiltersAndColumnsBuilder';
import { CustomPropertyType } from '../../Models/CustomPropertyType';
import { ContactsRequest } from '../../Api/ContactsRequest';
import { DealLostReasonRequest } from '../../Api/DealLostReasonRequest';
import { DealPipelineRequest } from '../../Api/DealPipelineRequest';
import { DealPipelineStageRequest as DealPipelineStageStateRequest } from '../../Api/DealPipelineStageStateRequest';
import { DealSavedViewsRequest } from '../../Api/DealSavedViewsRequest';
import { DealsRequest } from '../../Api/DealsRequest';
import { DealTypeRequest } from '../../Api/DealTypeRequest';
import { OrganisationsRequest } from '../../Api/OrganisationsRequest';
import { UserTypeRequest } from '../../Api/UserTypeRequest';
import { SavedInboundView } from '../../Components/SavedInboundView';
import { SavedViewType } from '../../Components/ViewsModal';
import { KnowledgeBaseUrlKey, KnowledgeBaseUrls } from '../../HelperFunctions/KnowledgeBaseUrls';
import UniqueKeyBuilder from '../../HelperFunctions/UniqueKeyBuilder';
import Contact from '../../Models/Contact';
import { CloseByDetails, Deal, FollowUpDetails } from '../../Models/Deal';
import { convertDealToEditState } from './helpers';
import DealLostReason from '../../Models/DealLostReason';
import { DealPipeline } from '../../Models/DealPipeline';
import DealPipelineStageState from '../../Models/DealPipelineStageState';
import DealType from '../../Models/DealType';
import { UniqueKeyType } from '../../Models/Enums';
import Organisation from '../../Models/Organisation';
import UserType from '../../Models/UserType';
import CampusDataGrid, {
    ActionBarMeta,
    AddSispMeta,
    DataGridMeta,
    FilterExtendedColumn,
    PromptMeta,
    ViewsMeta,
} from '../CampusDataGrid';
import DealsAddSisp from './DealsAddSisp';
import DealsBoardWrapper from './DealsBoardWrapper';
import DealsEditSisp from './DealsEditSisp';
import DealsPreviewSisp from './DealsPreviewSisp';

import DataGridHelper from '../DataGridHelper';
import './DealsTable.scss';

interface Props {
    searchFilterPlaceholder: string;
    dataGridUniqueKey: string;
    dataGridEntitySingular: string;
    dataGridEntityPlural: string;
    customProperties: any;
}

enum DealsColumnKey {
    ID,
    NAME,
    RELATES_TO,
    VALUE,
    STAGE,
    TYPE,
    OWNED_BY,
    FOLLOW_UP_DATE,
    CLOSE_BY_DATE,
    PIPELINE,
}

const DealsTable: FunctionComponent<Props> = (props: Props) => {
    // Legacy DealBoard Requirements
    const { store, persistor } = configureStore();
    const persistedViewLocalStorageKey = props.dataGridUniqueKey + '-persistSelectedView';
    const [selectedMultiView, setSelectedMultiView] = useState<string>(
        localStorage.getItem(persistedViewLocalStorageKey) ?? 'Table',
    );
    // New DataGrid
    const contactUrlPrefix = 'subscribers/contacts/view/';
    const organisationUrlPrefix = 'subscribers/organisations/view/';

    const editSispUniqueKey = UniqueKeyBuilder.make(props.dataGridUniqueKey, UniqueKeyType.EDIT_SISP);
    const previewSispUniqueKey = UniqueKeyBuilder.make(props.dataGridUniqueKey, UniqueKeyType.PREVIEW_SISP);

    const dealsRepository = useContext(RepositoryFactoryContext).getApiRepository(new DealsRequest());
    const dealUsersRepository = useContext(RepositoryFactoryContext).getApiRepository(new UserTypeRequest());
    const dealTypesRepository = useContext(RepositoryFactoryContext).getApiRepository(new DealTypeRequest());
    const dealPipelineStageStateRepository = useContext(RepositoryFactoryContext).getApiRepository(
        new DealPipelineStageStateRequest(),
    );

    const dictionary = useContext(DictionaryContext);

    const dealPipelinesRepository = useContext(RepositoryFactoryContext).getApiRepository(new DealPipelineRequest());
    const dealLostReasonsRepository = useContext(RepositoryFactoryContext).getApiRepository(
        new DealLostReasonRequest(),
    );
    const contactsRepository = useContext(RepositoryFactoryContext).getApiRepository(new ContactsRequest());
    const organisationsRepository = useContext(RepositoryFactoryContext).getApiRepository(new OrganisationsRequest());

    const userPermissions = useContext(UserPermissionsContext);

    // State: Columns
    const Columns: Record<DealsColumnKey, FilterExtendedColumn> = {
        [DealsColumnKey.ID]: {
            key: 'id',
            name: 'ID',
        },
        [DealsColumnKey.NAME]: {
            key: 'name',
            name: 'Name',
            sortable: true,
            renderCell: (fprops) => {
                const deal_row = fprops.row as Deal;
                const id = deal_row.id!;
                const name = deal_row.name;
                const url = `deals/view/${id}`;

                return (
                    <>
                        <a href={url}>{name}</a>
                        <HoveroverButton
                            contents="Preview"
                            showHoverover={true}
                            eventBusMessageTarget={previewSispUniqueKey}
                            eventBusMessage={deal_row}
                        />
                        <HoveroverButton
                            contents="Edit"
                            showHoverover={true}
                            eventBusMessageTarget={editSispUniqueKey}
                            eventBusMessage={deal_row}
                        />
                    </>
                );
            },
            width: '3fr',
        },
        [DealsColumnKey.RELATES_TO]: {
            key: 'relates_to_details',
            name: 'Relates To',
            sortable: true,
            renderCell: (fprops) => {
                const relates_to_contact = (fprops.row as Deal).relates_to_contact;
                const relates_to_organisation = (fprops.row as Deal).relates_to_organisation;
                if (relates_to_contact) {
                    const contactUrl = `${contactUrlPrefix}${relates_to_contact.id}`;
                    return (
                        <>
                            <a href={contactUrl}>{relates_to_contact.name}</a>
                        </>
                    );
                } else if (relates_to_organisation) {
                    const organisationUrl = `${organisationUrlPrefix}${relates_to_organisation.id}`;
                    return (
                        <>
                            <a href={organisationUrl}>{relates_to_organisation.name}</a>
                        </>
                    );
                } else {
                    return <></>;
                }
            },
            width: '1fr',
        },
        [DealsColumnKey.VALUE]: {
            key: 'value',
            name: 'Value',
            sortable: true,
            filterFieldType: filterTypes.FieldType.CURRENCY,
            renderCell: (fprops) => {
                const value = (fprops.row as Deal).value.formatted;
                return <>{value}</>;
            },
            width: '1fr',
        },
        [DealsColumnKey.STAGE]: {
            key: 'stage',
            name: 'Stage',
            sortable: true,
            renderCell: (fprops) => {
                const stage = (fprops.row as Deal).stage;
                const stage_name = stage ? stage.name : '';
                return <>{stage_name}</>;
            },
            width: '1fr',
        },
        [DealsColumnKey.TYPE]: {
            key: 'type',
            name: 'Type',
            sortable: true,
            filterFieldType: filterTypes.FieldType.ENUM_ARRAY,
            filterFieldAsyncOptions: (filter: string, page?: number) => {
                const query = new SearchQuery(1, 100, undefined, undefined, filter);
                return dealTypesRepository
                    .search(query)
                    .then((results: any) => {
                        return results.results.map((dealType: DealType) => {
                            return {
                                value: dealType.id,
                                name: dealType.type,
                            };
                        });
                    })
                    .catch((err: any) => {
                        return null;
                    });
            },
            width: '1fr',
        },
        [DealsColumnKey.OWNED_BY]: {
            key: 'owned_by',
            name: 'Owned By',
            sortable: true,
            renderCell: UserFormatter,
            filterFieldType: filterTypes.FieldType.ENUM_ARRAY,
            filterFieldAsyncOptions: (filter: string, page?: number) => {
                const query = new SearchQuery(1, 100, undefined, undefined, filter);
                return dealUsersRepository
                    .search(query)
                    .then((results: any) => {
                        return results.results.map((element: UserType) => {
                            return {
                                value: element.id,
                                name: element.name,
                            };
                        });
                    })
                    .catch((err: any) => {
                        return null;
                    });
            },
            width: '1fr',
        },
        [DealsColumnKey.FOLLOW_UP_DATE]: {
            key: 'follow_up_date',
            name: 'Follow Up Date',
            sortable: true,
            renderCell: (fprops) => {
                const follow_up_date = (fprops.row as Deal).follow_up_details as FollowUpDetails;
                return (
                    <>
                        <OverlayTrigger
                            overlay={<Tooltip id="builtin-tooltip">{follow_up_date.tooltip_label}</Tooltip>}
                            placement="right"
                        >
                            <span style={{ color: follow_up_date.is_overdue ? 'red' : '#333' }}>
                                {follow_up_date.follow_up_date}
                            </span>
                        </OverlayTrigger>
                    </>
                );
            },
            filterFieldType: filterTypes.FieldType.DATE,
            width: '1fr',
        },
        [DealsColumnKey.CLOSE_BY_DATE]: {
            key: 'close_by_date',
            name: 'Close By Date',
            sortable: true,
            renderCell: (fprops) => {
                const close_by_date = (fprops.row as Deal).close_by_details as CloseByDetails;
                return (
                    <>
                        <OverlayTrigger
                            overlay={<Tooltip id="builtin-tooltip">{close_by_date.tooltip_label}</Tooltip>}
                            placement="right"
                        >
                            <span style={{ color: close_by_date.is_overdue ? 'red' : '#333' }}>
                                {close_by_date.close_date}
                            </span>
                        </OverlayTrigger>
                    </>
                );
            },
            filterFieldType: filterTypes.FieldType.DATE,
            width: '1fr',
        },
        [DealsColumnKey.PIPELINE]: {
            key: 'pipeline',
            name: 'Pipeline',
            sortable: true,
            renderCell: (fprops) => {
                const pipeline_name = (fprops.row as Deal).pipeline?.name;
                return <>{pipeline_name}</>;
            },
            filterFieldType: filterTypes.FieldType.ENUM_ARRAY,
            filterFieldAsyncOptions: (filter: string, page?: number) => {
                const query = new SearchQuery(1, 100, undefined, undefined, filter);
                return dealPipelinesRepository
                    .search(query)
                    .then((results: any) => {
                        return results.results.map((element: DealPipeline) => {
                            return {
                                value: element.id,
                                name: element.name,
                            };
                        });
                    })
                    .catch((err: any) => {
                        return null;
                    });
            },
            width: '2fr',
        },
    };

    const DefaultColumns: ExtendedColumn[] = [
        Columns[DealsColumnKey.NAME],
        Columns[DealsColumnKey.RELATES_TO],
        Columns[DealsColumnKey.VALUE],
        Columns[DealsColumnKey.STAGE],
        Columns[DealsColumnKey.TYPE],
        Columns[DealsColumnKey.OWNED_BY],
        Columns[DealsColumnKey.FOLLOW_UP_DATE],
        Columns[DealsColumnKey.CLOSE_BY_DATE],
        Columns[DealsColumnKey.PIPELINE],
    ];

    const [dealCustomPropertyColumns, setDealCustomPropertyColumns] = useState<Record<any, FilterExtendedColumn>>();

    // On Did Mount
    // Set up custom property filters and columns
    useEffect(() => {
        if (userPermissions.customPropertiesDeals === ModulePermissions.ENABLED) {
            const { columns, customProperties } = buildCustomPropertiesFiltersAndColumns(
                props.customProperties,
                CustomPropertyType.DEAL,
            );
            setDealCustomPropertyColumns(columns);
            setFilterableFieldsDeals([...filterableFieldsDeals, ...customProperties]);
        }
    }, []);

    // Filters
    // this contains the fields that are used in the contacts filterSISP
    const [filterableFieldsDeals, setFilterableFieldsDeals] = useState<FilterExtendedColumn[]>([
        Columns[DealsColumnKey.PIPELINE],
        {
            key: 'stage_state',
            name: 'Stage',
            filterFieldType: filterTypes.FieldType.ENUM_ARRAY,
            filterFieldAsyncOptions: (filter: string, page?: number) => {
                const query = new SearchQuery(1, 100, undefined, undefined, filter);
                return dealPipelineStageStateRepository
                    .search(query)
                    .then((results: any) => {
                        return results.results.map((dealPipelineStageState: DealPipelineStageState) => {
                            return {
                                value: dealPipelineStageState.name,
                                name: dealPipelineStageState.name,
                            };
                        });
                    })
                    .catch((err: any) => {
                        return null;
                    });
            },
        },
        Columns[DealsColumnKey.TYPE],
        Columns[DealsColumnKey.OWNED_BY],
        {
            key: 'created_date',
            name: 'Created Date',
            filterFieldType: filterTypes.FieldType.DATE,
        },
        Columns[DealsColumnKey.FOLLOW_UP_DATE],
        Columns[DealsColumnKey.CLOSE_BY_DATE],
        {
            key: 'lost_reason',
            name: 'Lost Reason',
            filterFieldType: filterTypes.FieldType.ENUM_ARRAY,
            filterFieldAsyncOptions: (filter: string, page?: number) => {
                const query = new SearchQuery(1, 100, undefined, undefined, filter);
                return dealLostReasonsRepository
                    .search(query)
                    .then((results: any) => {
                        return results.results.map((element: DealLostReason) => {
                            return {
                                value: element.id,
                                name: element.reason,
                            };
                        });
                    })
                    .catch((err: any) => {
                        return null;
                    });
            },
        },
        Columns[DealsColumnKey.VALUE],
        {
            key: 'relates_to_contact',
            name: 'Relates To Contact',
            filterFieldType: filterTypes.FieldType.ENUM_ARRAY,
            filterFieldAsyncOptions: (filter: string, page?: number) => {
                const query = new SearchQuery(1, 100, 'co.firstname', SortOrder.ASC, filter);
                return contactsRepository
                    .search(query)
                    .then((results: any) => {
                        return results.results.map((element: Contact) => {
                            return {
                                value: element.id,
                                name: element.full_name,
                            };
                        });
                    })
                    .catch((err: any) => {
                        return null;
                    });
            },
        },
        {
            key: 'relates_to_organisation',
            name: 'Relates To ' + ucwords(dictionary['organisation']),
            filterFieldType: filterTypes.FieldType.ENUM_ARRAY,
            filterFieldAsyncOptions: (filter: string, page?: number) => {
                const query = new SearchQuery(1, 100, 'o.organisation_name', SortOrder.ASC, filter);
                return organisationsRepository
                    .search(query)
                    .then((results: any) => {
                        return results.results.map((element: Organisation) => {
                            return {
                                value: element.id,
                                name: element.name,
                            };
                        });
                    })
                    .catch((err: any) => {
                        return null;
                    });
            },
        },
        {
            key: 'created_by',
            name: 'Created By',
            filterFieldType: filterTypes.FieldType.ENUM_ARRAY,
            filterFieldAsyncOptions: (filter: string, page?: number) => {
                const query = new SearchQuery(1, 100, undefined, undefined, filter);
                return dealUsersRepository
                    .search(query)
                    .then((results: any) => {
                        return results.results.map((element: UserType) => {
                            return {
                                value: element.id,
                                name: element.name,
                            };
                        });
                    })
                    .catch((err: any) => {
                        return null;
                    });
            },
        },
        {
            key: 'modified_by',
            name: 'Modified By',
            filterFieldType: filterTypes.FieldType.ENUM_ARRAY,
            filterFieldAsyncOptions: (filter: string, page?: number) => {
                const query = new SearchQuery(1, 100, undefined, undefined, filter);
                return dealUsersRepository
                    .search(query)
                    .then((results: any) => {
                        return results.results.map((element: UserType) => {
                            return {
                                value: element.id,
                                name: element.name,
                            };
                        });
                    })
                    .catch((err: any) => {
                        return null;
                    });
            },
        },
    ]);

    const filterSections: filterTypes.FieldFilterSection[] = [
        {
            key: 'primaryFilters',
            name: 'Deal Filters',
            filterableFields: _.map(
                _.filter(filterableFieldsDeals, (c) => !!c.filterFieldType),
                (c) => {
                    return DataGridHelper.columnToFilter(c);
                },
            ),
        },
    ];

    // Edit Columns
    const getEditColumnOptions = (selected: readonly OptionTypeBase[]): Options<OptionTypeBase> => {
        const availableOptions = [];
        const selectedKeys = selected.map((option) => option.value.toString());
        const keys = Object.keys(Columns);
        for (let i = 0; i < keys.length; i++) {
            const key = keys[i] as unknown as DealsColumnKey;
            if (!selectedKeys.includes(key) && key != DealsColumnKey.ID) {
                availableOptions.push({
                    value: key,
                    label: Columns[key].name,
                    columnKey: Columns[key].key,
                });
            }
        }
        // Custom properties
        if (dealCustomPropertyColumns) {
            const customPropertyKeys = Object.keys(dealCustomPropertyColumns);
            for (let i = 0; i < customPropertyKeys.length; i++) {
                const key = customPropertyKeys[i];
                if (!selectedKeys.includes(key)) {
                    availableOptions.push({
                        value: key,
                        label: dealCustomPropertyColumns[key].name as string,
                        columnKey: dealCustomPropertyColumns[key].key,
                    });
                }
            }
        }
        return [
            { label: 'Selected', options: selected },
            { label: 'Deals', options: availableOptions },
        ];
    };

    // Views
    const defaultView: SavedInboundView = {
        id: 0,
        name: 'All Deals',
        dataGridColumns: DefaultColumns.map((column: ExtendedColumn) => {
            return {
                key: column.key,
                width: DEFAULT_COLUMN_WIDTH,
            };
        }),
    };

    const actionBarMeta: ActionBarMeta = {
        searchPlaceHolder: props.searchFilterPlaceholder,
        includeCounts: true,
        extraActionBarMeta: {
            getEditColumnOptionsDelegate: getEditColumnOptions,
            filterMeta: {
                defaultActiveKey: 'primaryFilters',
                fieldFilterSections: filterSections,
            },
        },
    };
    const viewsMeta: ViewsMeta = {
        request: new DealSavedViewsRequest(),
        savedViewType: SavedViewType.SAVED_SEARCH,
        entityTarget: EntityTarget.DEAL,
        defaultView: defaultView,
        checkLimitDelegate: async () => {
            const res = await fetch('/subscribers/lists/api/deal/check_list_limits');
            if (res.ok) {
                const json = await res.json();
                return json.data;
            } else {
                throw new Error('Error fetching Deal List limits');
            }
        },
        limitReachedListName: props.dataGridEntitySingular,
    };

    const addSispMeta: AddSispMeta = {
        key: UniqueKeyBuilder.make(props.dataGridUniqueKey, UniqueKeyType.ADD_SISP),
        sisp: DealsAddSisp,
    };

    const dataGridMeta: DataGridMeta = {
        uniqueKey: props.dataGridUniqueKey,
        entitySingular: props.dataGridEntitySingular,
        entityPlural: props.dataGridEntityPlural,
        columnOptions: { ...Columns, ...dealCustomPropertyColumns },
        defaultColumns: DefaultColumns,
        frozenColumns: [],
        draggableColumns: true,
        defaultSortColumn: 'id',
    };

    const promptMeta: PromptMeta = {
        icon: '/assets/application/img/prompts/deals.svg',
        helpCentreLink: KnowledgeBaseUrls.get(KnowledgeBaseUrlKey.ADD_DEAL),
    };

    return (
        <>
            <MultiViewSwitcher
                availableViews={['Table', 'Board']}
                selectedView={selectedMultiView}
                onSelect={(viewName) => {
                    setSelectedMultiView(viewName);
                    localStorage.setItem(persistedViewLocalStorageKey, viewName);
                }}
            />
            {selectedMultiView == 'Board' && (
                <div id="DealBoard">
                    <Provider store={store}>
                        <PersistGate persistor={persistor}>
                            <DealsBoardWrapper
                                repository={dealsRepository}
                                actionBarMeta={actionBarMeta}
                                addSispMeta={addSispMeta}
                                editSispMeta={{ sisp: DealsEditSisp }}
                                previewSispMeta={{ sisp: DealsPreviewSisp, key: previewSispUniqueKey }}
                                dataGridMeta={dataGridMeta}
                                viewsMeta={viewsMeta}
                                promptMeta={promptMeta}
                            />
                        </PersistGate>
                    </Provider>
                </div>
            )}
            {selectedMultiView == 'Table' && (
                <CampusDataGrid
                    repository={dealsRepository}
                    actionBarMeta={actionBarMeta}
                    addSispMeta={addSispMeta}
                    editSispMeta={{ sisp: DealsEditSisp }}
                    previewSispMeta={{ sisp: DealsPreviewSisp, key: previewSispUniqueKey }}
                    dataGridMeta={dataGridMeta}
                    viewsMeta={viewsMeta}
                    promptMeta={promptMeta}
                />
            )}
        </>
    );
};

export default DealsTable;
