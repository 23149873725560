import TasksType from '../Models/TasksType';
import DataGridRequest from './DataGridRequest';
export class TasksRequest extends DataGridRequest<TasksType> {
    Url = '/tasks/home/api';
}

export class TaskStatusRequest extends DataGridRequest<any> {
    Url = '/api/task_status/api';
}

export class TaskPriorityRequest extends DataGridRequest<any> {
    Url = '/api/task_priority/api';
}
