import { LogLevel, showBanner } from '@sprint/sprint-react-components';
import React, { BaseSyntheticEvent, FunctionComponent, useContext, useEffect } from 'react';
import { RepositoryFactoryContext } from '../../index';
import { ClientGroupsRequest } from '../../Api/ClientGroupsRequest';
import { Form } from 'react-bootstrap';
import { FieldValues, useForm } from 'react-hook-form';
import { ClientGroupType } from '../../Models/Enums';
import ClientGroup from '../../Models/ClientGroup';

interface Props {
    shown: boolean;
    onSubmit: (data: any) => void;
    clientGroupType: ClientGroupType;
    entitySingular: string;
    formId: string;
    setIsSubmitting: (submitting: boolean) => void;
    clientGroup?: ClientGroup;
}

type FormData = {
    id?: number;
    name: string;
};

const ClientGroupsForm: FunctionComponent<Props> = (props: Props) => {
    const genericGroupsRepository = useContext(RepositoryFactoryContext).getApiRepository(
        new ClientGroupsRequest(props.clientGroupType),
    );

    const {
        register,
        handleSubmit,
        reset,
        setFocus,
        setValue,
        formState: { errors, isSubmitting },
    } = useForm<FormData>({ mode: 'all' });

    useEffect(() => {
        if (props.shown) {
            if (props.clientGroup) {
                setValue('id', props.clientGroup.id);
                setValue('name', props.clientGroup.name);
            }
            setTimeout(() => setFocus('name', { shouldSelect: !props.clientGroup }), 50);
        } else {
            reset();
        }
    }, [props.shown]);

    useEffect(() => {
        props.setIsSubmitting(isSubmitting);
    }, [isSubmitting]);

    const handleRepositoryAction = async (data: FieldValues): Promise<boolean> => {
        const action = data.id ? 'update' : 'create';
        return (data.id ? genericGroupsRepository.update(data) : genericGroupsRepository.create(data))
            .then((results: any) => {
                props.onSubmit(results.data);
                showBanner({
                    message: `${props.entitySingular} ${action}d successfully`,
                    level: LogLevel.SUCCESS,
                });
                return true;
            })
            .catch((err) => {
                showBanner({
                    message: `Failed to ${action} ${props.entitySingular} - ` + (err?.message ?? err),
                    level: LogLevel.ERROR,
                });
                return false;
            });
    };

    const onSubmitForm = async (data: FieldValues, event?: BaseSyntheticEvent<unknown, any, any>) => {
        event?.preventDefault();
        if (await handleRepositoryAction(data)) {
            reset();
        }
    };

    return (
        <Form id={props.formId} onSubmit={handleSubmit(onSubmitForm)}>
            <Form.Group className={errors.name ? 'has-error' : ''}>
                <Form.Label>
                    Name <span className="required-field-marker">*</span>
                </Form.Label>
                <Form.Control {...register('name', { required: 'This field is required.' })} autoComplete="off" />
                {errors.name && <span className="help-block">{errors.name.message}</span>}
            </Form.Group>
        </Form>
    );
};

export default ClientGroupsForm;
