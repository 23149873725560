import {
    AppEvent,
    AsyncDropDownPaginated,
    Checkbox,
    DropDown,
    EventBusInstance,
    isValidString,
    LogLevel,
    OptionTypeBase,
    OptionTypeBaseUserFormatter,
    SearchQuery,
    SearchQueryBuilder,
    showBanner,
    Sisp,
    SortOrder,
} from '@sprint/sprint-react-components';
import { format, parse } from 'date-fns';
import _ from 'lodash';
import React, { FormEvent, FunctionComponent, useContext, useEffect, useRef, useState } from 'react';
import { Card, Form, OverlayTrigger, Spinner, Tooltip } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { ucwords } from '../../../../Helpers/StringHelper';
import { ContactsRequest } from '../../Api/ContactsRequest';
import { DealsRequest } from '../../Api/DealsRequest';
import { OrganisationsRequest } from '../../Api/OrganisationsRequest';
import { TasksRequest } from '../../Api/TasksRequest';
import { TaskTypeRequest } from '../../Api/TaskTypeRequest';
import { UserTypeRequest } from '../../Api/UserTypeRequest';
import { DictionaryContext, RepositoryFactoryContext, UserPermissionsContext } from '../../index';
import SimpleFilterRequestBuilder from '../../HelperFunctions/SimpleFilterRequestBuilder';
import Contact from '../../Models/Contact';
import { Deal } from '../../Models/Deal';
import { TasksPriority, TasksStatus } from '../../Models/Enums';
import Organisation from '../../Models/Organisation';
import TasksType, { TasksTypeEditState } from '../../Models/TasksType';
import TaskType from '../../Models/TaskType';
import UserType from '../../Models/UserType';
import { ModulePermissions } from '../../../EducationDataGrid/models/Enums';
import CustomPropertyForm, { AvailablePropertyTypes } from '../CustomProperties/CustomPropertyForm';
import './TasksSisps.scss';

interface Props {
    uniqueKey: string;
    onSuccess: (event: any) => Promise<boolean>;
}

const TasksEditSisp: FunctionComponent<Props> = (props: Props) => {
    const tasksRepository = useContext(RepositoryFactoryContext).getApiRepository(new TasksRequest());
    const taskTypeRepository = useContext(RepositoryFactoryContext).getApiRepository(new TaskTypeRequest());
    const usersRepository = useContext(RepositoryFactoryContext).getApiRepository(new UserTypeRequest());
    const dealsRepository = useContext(RepositoryFactoryContext).getApiRepository(new DealsRequest());
    const contactsRepository = useContext(RepositoryFactoryContext).getApiRepository(new ContactsRequest());
    const organisationsRepository = useContext(RepositoryFactoryContext).getApiRepository(new OrganisationsRequest());

    const dictionary = useContext(DictionaryContext);
    const userPermissions = useContext(UserPermissionsContext);

    // State: General
    const [shown, setShown] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const focusRef = useRef<HTMLInputElement>(null);

    const [rowId, setRowId] = useState<number>(0);

    const [editName, setEditName] = useState<string>('');
    const [editStatus, setEditStatus] = useState<OptionTypeBase | null>(null);
    const editRelatesToTypeDefault = {
        none: false,
        contact: false,
        organisation: false,
        deal: false,
    };
    const [editRelatesToType, setEditRelatesToType] = useState(editRelatesToTypeDefault);
    const [editRelatesTo, setEditRelatesTo] = useState<OptionTypeBase | null>(null);
    const [editPriority, setEditPriority] = useState<OptionTypeBase | null>(null);
    const [editDueByDate, setEditDueByDate] = useState<Date | null>(null);
    const [editCompletedByDate, setEditCompletedByDate] = useState<Date | null>(null);
    const [editType, setEditType] = useState<OptionTypeBase | null>(null);
    const [editAssignedTo, setEditAssignedTo] = useState<OptionTypeBase | null>(null);
    const [editPrivateTask, setEditPrivateTask] = useState<boolean>(false);
    const [editDescription, setEditDescription] = useState<string>('');
    const [editAssignedBy, setEditAssignedBy] = useState<OptionTypeBase | null>(null);
    const [editCustomProperties, setEditCustomProperties] = useState<{ [key: string]: string }>({});

    const validationStateDefault = {
        name: true,
        status: true,
        priority: true,
        type: true,
        assignedTo: true,
        relatesTo: true,
        assignedBy: true,
    };
    const [validationState, setValidationState] = useState(validationStateDefault);

    const [taskTypes, setTaskTypes] = useState<OptionTypeBase[]>();
    const [currentUserId, setCurrentUserId] = useState<number | null>(null);
    const [users, setUsers] = useState<OptionTypeBase[]>();

    const [customPropertyValues, setCustomPropertyValues] = useState<any>();
    const customProperties: any = JSON.parse(
        String((document.getElementById('custom-properties') as HTMLInputElement).value),
    );

    const getTask = async (id: number): Promise<TasksType | null> => {
        const filterRequest = SimpleFilterRequestBuilder.idFilter(id.toString());
        return tasksRepository
            .filter(filterRequest)
            .then((results: any) => {
                return results.results[0];
            })
            .catch((err: any) => {
                showBanner({
                    message: 'Failed to get Task - ' + (err?.message ?? err),
                    level: LogLevel.ERROR,
                    dismissable: false,
                });
                return null;
            });
    };

    const getTaskTypes = async (): Promise<OptionTypeBase[] | null> => {
        return taskTypeRepository
            .search(new SearchQueryBuilder().build())
            .then((results: any) => {
                return results.results.map((taskType: TaskType) => {
                    return { value: taskType.id, label: taskType.type };
                });
            })
            .catch((err: any) => {
                showBanner({
                    message: 'Failed to get Task Types - ' + (err?.message ?? err),
                    level: LogLevel.ERROR,
                    dismissable: false,
                });
                return null;
            });
    };

    const getUsers = async (): Promise<OptionTypeBase[] | null> => {
        return usersRepository
            .search(new SearchQueryBuilder().build())
            .then((results: any) => {
                return results.results.map((user: UserType) => {
                    if (user.name === 'You') {
                        setCurrentUserId(user.id ?? null);
                    }
                    return OptionTypeBaseUserFormatter(user);
                });
            })
            .catch((err: any) => {
                showBanner({
                    message: 'Failed to get Users - ' + (err?.message ?? err),
                    level: LogLevel.ERROR,
                    dismissable: false,
                });
                return null;
            });
    };

    const getContacts = async (filter: string, page?: number) => {
        // Build filterRequest
        const query: SearchQuery = new SearchQuery(page ?? 1, 5, 'co.firstname', SortOrder.ASC, filter);
        let res: OptionTypeBase[] = [];

        return contactsRepository
            .search(query)
            .then((result: any) => {
                res = _.map(result.results, (contact: Contact) => {
                    return {
                        value: contact.id,
                        label: `${contact.full_name} [${contact.organisation.name}]`,
                    };
                });
                res.push({
                    value: result.counts.currentPage < result.counts.totalPages,
                    label: 'has_more',
                });
                return res;
            })
            .catch((err) => {
                showBanner({
                    message: 'Failed to get Contacts - ' + (err?.message ?? err),
                    level: LogLevel.ERROR,
                });
                return res;
            });
    };

    const getOrganisations = async (filter: string, page?: number) => {
        // Build filterRequest
        const query: SearchQuery = new SearchQuery(page ?? 1, 5, 'o.organisation_name', SortOrder.ASC, filter);
        let res: OptionTypeBase[] = [];

        return organisationsRepository
            .search(query)
            .then((result: any) => {
                res = _.map(result.results, (organisation: Organisation) => {
                    return {
                        value: organisation.id,
                        label: organisation.name,
                    };
                });
                res.push({
                    value: result.counts.currentPage < result.counts.totalPages,
                    label: 'has_more',
                });
                return res;
            })
            .catch((err) => {
                showBanner({
                    message: 'Failed to get Organisations - ' + (err?.message ?? err),
                    level: LogLevel.ERROR,
                });
                return res;
            });
    };

    const getDeals = async (filter: string, page?: number) => {
        // Build filterRequest
        const query: SearchQuery = new SearchQuery(page ?? 1, 10, 'd.name', SortOrder.ASC, filter);
        let res: OptionTypeBase[] = [];

        return dealsRepository
            .search(query)
            .then((result: any) => {
                res = _.map(result.results, (deal: Deal) => {
                    return {
                        value: deal.id,
                        label: `${deal.name} [${deal.value.formatted}]`,
                    };
                });
                res.push({
                    value: result.counts.currentPage < result.counts.totalPages,
                    label: 'has_more',
                });
                return res;
            })
            .catch((err) => {
                showBanner({
                    message: 'Failed to get Deals - ' + (err?.message ?? err),
                    level: LogLevel.ERROR,
                });
                return res;
            });
    };

    const parseEventMessage = (
        message: TasksType,
        fetchedTaskTypes: OptionTypeBase[],
        fetchedUsers: OptionTypeBase[],
    ) => {
        setRowId(message.id!);
        setEditName(message.name.value);

        updateRelatesToType('none');
        if (message.relates_to_deal) {
            updateRelatesToType('deal');
            setEditRelatesTo({
                value: message.relates_to_deal.id,
                label: message.relates_to_deal.pretty_format,
            });
        } else if (message.relates_to_contact) {
            updateRelatesToType('contact');
            setEditRelatesTo({
                value: message.relates_to_contact.id,
                label: message.relates_to_contact.pretty_format,
            });
        } else if (message.relates_to_organisation) {
            updateRelatesToType('organisation');
            setEditRelatesTo({
                value: message.relates_to_organisation.id,
                label: message.relates_to_organisation.pretty_format,
            });
        }

        let taskStatus = null;
        Object.values(TasksStatus).forEach((value) => {
            if (value === message.status) {
                taskStatus = { value: message.status, label: message.status };
            }
        });
        setEditStatus(taskStatus);

        let taskPriority = null;
        Object.values(TasksPriority).forEach((value) => {
            if (value === message.priority) {
                taskPriority = { value: message.priority, label: message.priority };
            }
        });
        setEditPriority(taskPriority);

        setEditDueByDate(message.due_date.date ? parse(message.due_date.date, 'do, LLL yyyy h:mma', new Date()) : null);
        setEditCompletedByDate(
            message.completed_date ? parse(message.completed_date, 'do, LLL yyyy', new Date()) : null,
        );

        let taskType = null;
        fetchedTaskTypes.forEach((fetchedTaskType: OptionTypeBase) => {
            if (fetchedTaskType.label === message.type) {
                taskType = fetchedTaskType;
            }
        });
        setEditType(taskType);

        let assignedToUser = null;
        if (message.assigned_to?.id) {
            fetchedUsers.forEach((fetchedUser: OptionTypeBase) => {
                if (fetchedUser.value === message.assigned_to.id) {
                    assignedToUser = fetchedUser;
                }
            });
        }
        setEditAssignedTo(assignedToUser);

        setEditPrivateTask(message.is_private);
        setEditDescription(message.description ?? '');

        let assignedByUser = null;
        if (message.assigned_by?.id) {
            fetchedUsers.forEach((fetchedUser: OptionTypeBase) => {
                if (fetchedUser.value === message.assigned_by.id) {
                    assignedByUser = fetchedUser;
                }
            });
        }
        setEditAssignedBy(assignedByUser);

        setTaskTypes(fetchedTaskTypes);
        setUsers(fetchedUsers);

        setCustomPropertyValues(message.custom_properties);
        setIsLoading(false);
    };

    const reset = () => {
        setEditName('');
        setEditRelatesToType(editRelatesToTypeDefault);
        setEditRelatesTo(null);
        setEditStatus(null);
        setEditPriority(null);
        setEditDueByDate(null);
        setEditCompletedByDate(null);
        setEditType(null);
        setEditAssignedTo(null);
        setEditPrivateTask(false);
        setEditDescription('');
        setEditCustomProperties({});
        setCurrentUserId(null);
        setCustomPropertyValues(undefined);
        setValidationState(validationStateDefault);
        setIsLoading(true);
    };

    useEffect(() => {
        EventBusInstance.subscribe('show-hoverover-component', (event: AppEvent<TasksType>) => {
            if (event.target !== props.uniqueKey) return;
            reset();
            setShown(true);
            const getTaskData = 'id' in event.message && Object.keys(event.message).length === 1;
            const promises: [
                Promise<TasksType | null>,
                Promise<OptionTypeBase[] | null>,
                Promise<OptionTypeBase[] | null>,
            ] = [getTaskData ? getTask(event.message.id!) : Promise.resolve(event.message), getTaskTypes(), getUsers()];

            Promise.all(promises).then(([taskData, taskTypes, users]) => {
                if (taskData && taskTypes && users) {
                    parseEventMessage(taskData, taskTypes, users);
                } else {
                    setShown(false);
                }
            });
        });
    }, []);

    useEffect(() => {
        if (!isLoading) {
            if (focusRef.current) {
                focusRef.current.focus();
                focusRef.current.selectionStart = focusRef.current.value.length;
                focusRef.current.selectionEnd = focusRef.current.value.length;
            }
        }
    }, [isLoading]);

    const updateRelatesToType = (relatesToType: string) => {
        setEditRelatesToType({ ...editRelatesToTypeDefault, [relatesToType]: true });
        setEditRelatesTo(null);
        setValidationState((prevState) => {
            return { ...prevState, relatesTo: true };
        });
    };

    const validate = async (): Promise<boolean> => {
        let relatesToValid = true;

        if (editRelatesToType.contact || editRelatesToType.organisation || editRelatesToType.deal) {
            relatesToValid = editRelatesTo !== null;
        }

        const editValidationState = {
            name: !!editName && isValidString(editName),
            status: editStatus != null,
            priority: editPriority !== null,
            type: editType !== null,
            assignedTo: editAssignedTo !== null,
            relatesTo: relatesToValid,
            assignedBy: editAssignedBy !== null,
        };
        setValidationState(editValidationState);

        return _.every(editValidationState);
    };

    const handleEditRow = async (): Promise<boolean> => {
        let editRelatesToStr = '';
        for (const [key, value] of Object.entries(editRelatesToType)) {
            if (value) {
                editRelatesToStr = key;
                break;
            }
        }

        const editTask: TasksTypeEditState = {
            id: rowId,
            name: editName,
            status: editStatus!.value,
            description: editDescription,
            task_type_id: editType!.value,
            assigned_to_id: editAssignedTo!.value,
            assigned_by_id: editAssignedBy!.value,
            is_private: editPrivateTask,
            due_date: editDueByDate && format(editDueByDate!, 'yyyy-MM-dd HH:mm:ss'),
            completed_date: editCompletedByDate && format(editCompletedByDate!, 'yyyy-MM-dd HH:mm:ss'),
            priority: editPriority!.value,
            relates_to: editRelatesToStr,
            relates_to_id: editRelatesToStr !== 'none' ? editRelatesTo!.value : null,
        };

        return tasksRepository
            .update({ ...editTask, ...editCustomProperties })
            .then((results: any) => {
                props.onSuccess(results.data);
                return Promise.resolve(true);
            })
            .catch((err) => {
                showBanner({
                    message: 'Failed to edit Task details - ' + (err?.message ?? err),
                    level: LogLevel.ERROR,
                });
                return Promise.resolve(false);
            });
    };

    const onSubmitForm = async (e: FormEvent) => {
        e.preventDefault();
        if ((await validate()) && (await handleEditRow())) setShown(false);
    };

    const setCustomPropertyValue = (key: any, value: any) => {
        // This is needed to stop an infinite render loop
        if (!(key in editCustomProperties && editCustomProperties[key] == value)) {
            setEditCustomProperties((prevState) => ({
                ...prevState,
                [key]: value,
            }));
        }
    };

    return (
        <Sisp
            className="tasks-sisp-add"
            isOpen={shown}
            onSubmit={handleEditRow}
            onCancel={() => {
                setShown(false);
            }}
            validate={validate}
        >
            <h4>Edit Task</h4>
            {isLoading ? (
                <div style={{ position: 'relative', alignItems: 'center' }}>
                    <Card
                        className="loading-spinner-container filter-loading-spinner"
                        style={{ background: '#f9f9f9' }}
                    >
                        <Spinner animation="border" role="status" />
                    </Card>
                </div>
            ) : (
                <Form onSubmit={onSubmitForm}>
                    <Form.Group>
                        <Form.Label>
                            Task Name <span className="required-field-marker">*</span>
                        </Form.Label>
                        <Form.Control
                            autoComplete="off"
                            ref={focusRef}
                            type="text"
                            isInvalid={!validationState.name}
                            value={editName || ''}
                            onChange={(event) => {
                                setEditName(event.target.value);
                            }}
                        />
                        <Form.Control.Feedback type="invalid">
                            {!validationState.name && 'This field is required.'}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Relates To</Form.Label>
                        <div className="radio-button-group align-horizontal">
                            <Form.Check
                                className="radio-button"
                                id="relates-to-none-edit"
                                name="relates-to"
                                type="radio"
                                label="None"
                                custom
                                checked={editRelatesToType.none}
                                onChange={() => updateRelatesToType('none')}
                            />
                            <Form.Check
                                className="radio-button"
                                id="relates-to-contact-edit"
                                name="relates-to"
                                type="radio"
                                label="Contact"
                                custom
                                checked={editRelatesToType.contact}
                                onChange={() => updateRelatesToType('contact')}
                            />
                            <Form.Check
                                className="radio-button"
                                id="relates-to-organisation-edit"
                                name="relates-to"
                                type="radio"
                                label={ucwords(dictionary['organisation'])}
                                custom
                                checked={editRelatesToType.organisation}
                                onChange={() => updateRelatesToType('organisation')}
                            />
                            <Form.Check
                                className="radio-button"
                                id="relates-to-deal-edit"
                                name="relates-to"
                                type="radio"
                                label="Deal"
                                custom
                                checked={editRelatesToType.deal}
                                onChange={() => updateRelatesToType('deal')}
                            />
                        </div>
                    </Form.Group>
                    {editRelatesToType.contact && (
                        <Form.Group>
                            <Form.Label>
                                Contacts <span className="required-field-marker">*</span>
                            </Form.Label>
                            <AsyncDropDownPaginated
                                id={'contacts_dropdown'}
                                value={editRelatesTo}
                                isInvalid={!validationState.relatesTo}
                                menuPlacement="auto"
                                menuPosition="fixed"
                                menuPortalTarget={document.body}
                                onChange={(selected: OptionTypeBase) => {
                                    setEditRelatesTo(selected);
                                }}
                                loadOptions={async (filter: string, _loadedOptions, { page }) => {
                                    let res = await getContacts(filter, page);
                                    // Get has_more entry from results
                                    const hasMore = res.find((obj) => obj.label === 'has_more');
                                    // Remove has_more entry from main results
                                    res = _.filter(res, (obj) => obj.label !== 'has_more');
                                    return {
                                        options: res,
                                        hasMore: hasMore?.value as boolean,
                                        additional: {
                                            page: page + 1,
                                        },
                                    };
                                }}
                            />
                            <Form.Control.Feedback type="invalid">
                                {!validationState.relatesTo && 'This field is required.'}
                            </Form.Control.Feedback>
                        </Form.Group>
                    )}
                    {editRelatesToType.organisation && (
                        <Form.Group>
                            <Form.Label>
                                {ucwords(dictionary['organisation'])}s <span className="required-field-marker">*</span>
                            </Form.Label>
                            <AsyncDropDownPaginated
                                id={'organisations_dropdown'}
                                value={editRelatesTo}
                                isInvalid={!validationState.relatesTo}
                                menuPlacement="auto"
                                menuPosition="fixed"
                                menuPortalTarget={document.body}
                                onChange={(selected: OptionTypeBase) => {
                                    setEditRelatesTo(selected);
                                }}
                                loadOptions={async (filter: string, _loadedOptions, { page }) => {
                                    let res = await getOrganisations(filter, page);
                                    // Get has_more entry from results
                                    const hasMore = res.find((obj) => obj.label === 'has_more');
                                    // Remove has_more entry from main results
                                    res = _.filter(res, (obj) => obj.label !== 'has_more');
                                    return {
                                        options: res,
                                        hasMore: hasMore?.value as boolean,
                                        additional: {
                                            page: page + 1,
                                        },
                                    };
                                }}
                            />
                            <Form.Control.Feedback type="invalid">
                                {!validationState.relatesTo && 'This field is required.'}
                            </Form.Control.Feedback>
                        </Form.Group>
                    )}
                    {editRelatesToType.deal && (
                        <Form.Group>
                            <Form.Label>
                                Deals <span className="required-field-marker">*</span>
                            </Form.Label>
                            <AsyncDropDownPaginated
                                id={'deals_dropdown'}
                                value={editRelatesTo}
                                isInvalid={!validationState.relatesTo}
                                menuPlacement="auto"
                                menuPosition="fixed"
                                menuPortalTarget={document.body}
                                onChange={(selected: OptionTypeBase) => {
                                    setEditRelatesTo(selected);
                                }}
                                loadOptions={async (filter: string, _loadedOptions, { page }) => {
                                    let res = await getDeals(filter, page);
                                    // Get has_more entry from results
                                    const hasMore = res.find((obj) => obj.label === 'has_more');
                                    // Remove has_more entry from main results
                                    res = _.filter(res, (obj) => obj.label !== 'has_more');
                                    return {
                                        options: res,
                                        hasMore: hasMore?.value as boolean,
                                        additional: {
                                            page: page + 1,
                                        },
                                    };
                                }}
                            />
                            <Form.Control.Feedback type="invalid">
                                {!validationState.relatesTo && 'This field is required.'}
                            </Form.Control.Feedback>
                        </Form.Group>
                    )}
                    <Form.Group>
                        <Form.Label>
                            Status <span className="required-field-marker">*</span>
                        </Form.Label>
                        <DropDown
                            value={editStatus}
                            isInvalid={!validationState.status}
                            onChange={(selected: OptionTypeBase) => {
                                setEditStatus(selected);
                            }}
                            options={Object.values(TasksStatus).map((value: string) => {
                                return {
                                    value: value,
                                    label: value,
                                };
                            })}
                            menuPosition="fixed"
                        />
                        <Form.Control.Feedback type="invalid">
                            {!validationState.status && 'This field is required.'}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>
                            Priority <span className="required-field-marker">*</span>
                        </Form.Label>
                        <DropDown
                            value={editPriority}
                            isInvalid={!validationState.priority}
                            onChange={(selected: OptionTypeBase) => {
                                setEditPriority(selected);
                            }}
                            options={Object.values(TasksPriority).map((value: string) => {
                                return {
                                    value: value,
                                    label: value,
                                };
                            })}
                            menuPosition="fixed"
                        />
                        <Form.Control.Feedback type="invalid">
                            {!validationState.priority && 'This field is required.'}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="tasks-date">
                        <Form.Label>Due By</Form.Label>
                        <DatePicker
                            className="tasks-date-picker"
                            selected={editDueByDate}
                            onChange={(date: Date) => setEditDueByDate(date)}
                            dateFormat="dd/MM/yyyy h:mm aaa"
                            showTimeInput
                            timeFormat="h:mm aa"
                            timeIntervals={1}
                            isClearable={true}
                            shouldCloseOnSelect={false}
                        />
                    </Form.Group>
                    {editStatus!.value === TasksStatus.COMPLETE && (
                        <Form.Group className="tasks-date">
                            <Form.Label>Completed On</Form.Label>
                            <DatePicker
                                className="tasks-date-picker"
                                selected={editCompletedByDate}
                                onChange={(date: Date) => setEditCompletedByDate(date)}
                                dateFormat="dd/MM/yyyy"
                                isClearable={true}
                            />
                        </Form.Group>
                    )}
                    <Form.Group>
                        <Form.Label>
                            Type <span className="required-field-marker">*</span>
                        </Form.Label>
                        <DropDown
                            value={editType}
                            isInvalid={!validationState.type}
                            onChange={(selected: OptionTypeBase) => {
                                setEditType(selected);
                            }}
                            options={taskTypes!}
                            menuPosition="fixed"
                        />
                        <Form.Control.Feedback type="invalid">
                            {!validationState.type && 'This field is required.'}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>
                            Assigned To <span className="required-field-marker">*</span>
                            {currentUserId && (
                                <a
                                    style={{ position: 'absolute', right: '20px', textDecoration: 'none' }}
                                    href=""
                                    className="rdg-link"
                                    onClick={(event) => {
                                        event.preventDefault();
                                        users!.every((option: OptionTypeBase) => {
                                            if (option.value === currentUserId) {
                                                setEditAssignedTo(option);
                                                return false;
                                            }
                                            return true;
                                        });
                                    }}
                                >
                                    Assign to me
                                </a>
                            )}
                        </Form.Label>
                        <DropDown
                            value={editAssignedTo}
                            isInvalid={!validationState.assignedTo}
                            onChange={(selected: OptionTypeBase) => {
                                setEditAssignedTo(selected);
                            }}
                            options={users!}
                            menuPosition="fixed"
                        />
                        <Form.Control.Feedback type="invalid">
                            {!validationState.assignedTo && 'This field is required.'}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group>
                        <OverlayTrigger
                            overlay={
                                <Tooltip id={'private-task-tooltip'}>
                                    If private, only assigned to users can see this task.
                                </Tooltip>
                            }
                            placement="right"
                        >
                            <div style={{ width: '25%' }}>
                                <Checkbox
                                    label="Private Task"
                                    isChecked={editPrivateTask}
                                    onChange={(event) => setEditPrivateTask(event.target.checked)}
                                />
                            </div>
                        </OverlayTrigger>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>
                            Assigned By <span className="required-field-marker">*</span>
                        </Form.Label>
                        <DropDown
                            value={editAssignedBy}
                            isInvalid={!validationState.assignedBy}
                            onChange={(selected: OptionTypeBase) => {
                                setEditAssignedBy(selected);
                            }}
                            options={users!}
                            menuPosition="fixed"
                        />
                        <Form.Control.Feedback type="invalid">
                            {!validationState.assignedBy && 'This field is required.'}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Description</Form.Label>
                        <Form.Control
                            as="textarea"
                            style={{
                                minHeight: '10rem',
                            }}
                            value={editDescription}
                            onChange={(event) => setEditDescription((event.target as HTMLTextAreaElement).value)}
                        />
                    </Form.Group>
                    {userPermissions.customPropertiesTasks === ModulePermissions.ENABLED && (
                        <CustomPropertyForm
                            propertyType={AvailablePropertyTypes.tasks}
                            customProperties={customProperties}
                            customPropertyValues={customPropertyValues}
                            updateFormPropertyState={setCustomPropertyValues}
                            setPropertyValue={setCustomPropertyValue}
                        />
                    )}
                </Form>
            )}
        </Sisp>
    );
};

export default TasksEditSisp;
