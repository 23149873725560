import {
    AppEvent,
    AsyncDropDown,
    EventBusInstance,
    LogLevel,
    Modal,
    ModalType,
    OptionTypeBase,
    PendingButton,
    SearchQuery,
    Sisp,
    isValidString,
    showBanner,
} from '@sprint/sprint-react-components';
import React, { FormEvent, FunctionComponent, useContext, useEffect, useRef, useState } from 'react';
import { Button, Card, Form, Spinner } from 'react-bootstrap';
import { CustomPropertiesRequest } from '../../Api/CustomPropertiesRequest';
import { CustomPropertiesTypeRequest } from '../../Api/CustomPropertiesTypeRequest';
import { CustomPropertiesDataTypeRequest } from '../../Api/CustomPropertiesDataTypeRequest';
import { ClientGroupsRequest } from '../../Api/ClientGroupsRequest';
import CustomProperty from '../../Models/CustomProperty';
import CustomPropertyDataType from '../../Models/CustomPropertyDataType';
import CustomPropertyEditState from '../../Models/CustomPropertyEditState';
import { RepositoryFactoryContext, UserPermissionsContext } from '../../index';
import { ClientGroupType } from '../../Models/Enums';
import ClientGroup from '../../Models/ClientGroup';
import { ModulePermissions } from '../../../EducationDataGrid/models/Enums';

interface Props {
    uniqueKey: string;
    onSuccess: (results: any) => Promise<boolean>;
    reloadDataGrid: () => void;
}

const CustomPropertiesEditSisp: FunctionComponent<Props> = (props: Props) => {
    const userPermissions = useContext(UserPermissionsContext);
    const [shown, setShown] = useState<boolean>(false);
    const archiveModalUniqueKey = [props.uniqueKey, 'archive'].join('-');
    const restoreModalUniqueKey = [props.uniqueKey, 'restore'].join('-');

    const [isEdit, setIsEdit] = useState<boolean>(true);
    const [isArchive, setIsArchive] = useState<boolean>(false);
    const [isRestore, setIsRestore] = useState<boolean>(false);

    const customPropertiesRepository = useContext(RepositoryFactoryContext).getApiRepository(
        new CustomPropertiesRequest(),
    );
    const customPropertiesTypeRepository = useContext(RepositoryFactoryContext).getApiRepository(
        new CustomPropertiesTypeRequest(),
    );
    const customPropertiesDataTypeRepository = useContext(RepositoryFactoryContext).getApiRepository(
        new CustomPropertiesDataTypeRequest(),
    );
    const customPropertiesClientGroupsRepository = useContext(RepositoryFactoryContext).getApiRepository(
        new ClientGroupsRequest(ClientGroupType.CUSTOM_PROPERTIES),
    );

    const focusRef = useRef<HTMLInputElement>(null);
    const [dropdownOptions, setDropdownOptions] = useState<CustomPropertyDataType[]>([]);
    const [isLoaded, setIsLoaded] = useState<boolean>(false);

    const [hasOptions, setHasOptions] = useState<boolean>(false);

    const [rowId, setRowId] = useState<number>(0);

    const [editName, setEditName] = useState<string>('');
    const [editNameValid, setEditNameValid] = useState<boolean>(true);

    const [editDescription, setEditDescription] = useState<string>('');

    const [editPropertyType, setEditPropertyType] = useState<OptionTypeBase | null>(null);
    const [dataType, setDataType] = useState<string | null>(null);
    const [archived, setArchived] = useState<boolean>(false);

    const [editOptions, setEditOptions] = useState<string>('');
    const [editOptionsValid, setEditOptionsValid] = useState<boolean>(true);

    const [editClientGroup, setEditClientGroup] = useState<OptionTypeBase | null>(null);

    const [editSortOrder, setEditSortOrder] = useState<string>('');
    const [editSortOrderValid, setEditSortOrderValid] = useState<boolean>(true);

    const [editShowInForms, setShowInForms] = useState<boolean>(false);
    const [editShowInViews, setShowInViews] = useState<boolean>(false);

    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    useEffect(() => {
        if (shown) {
            if (focusRef.current) {
                focusRef.current.focus();
                focusRef.current.selectionStart = focusRef.current.value.length;
                focusRef.current.selectionEnd = focusRef.current.value.length;
            }
            setEditNameValid(true);
            setEditOptionsValid(true);
            setEditSortOrderValid(true);
        }
    }, [shown]);

    useEffect(() => {
        EventBusInstance.subscribe('show-hoverover-component', (event: AppEvent<CustomPropertyEditState>) => {
            setIsLoaded(false);
            if (
                event.target !== props.uniqueKey &&
                event.target !== archiveModalUniqueKey &&
                event.target !== restoreModalUniqueKey
            )
                return;

            if (event.target == props.uniqueKey) {
                setIsEdit(true);
                setIsArchive(false);
                setIsRestore(false);
                setShown(true);
                setArchived(event.message.archived);
            } else {
                const archiveValue = event.target == archiveModalUniqueKey;
                setIsEdit(false);
                setIsArchive(archiveValue);
                setIsRestore(!archiveValue);
                setArchived(archiveValue);
            }
            const promises = [collectDataTypeDropdownOptions(), getPropertyTypes('')];
            if (userPermissions.clientGroupsCustomProperties === ModulePermissions.ENABLED) {
                promises.push(getClientGroups(''));
            }
            Promise.all(promises).then((data) => {
                const dataTypeResults: CustomPropertyDataType[] = data[0];
                const typeResults: OptionTypeBase[] = data[1];

                // Need to do this once the options have loaded
                const dataType = dataTypeResults.find(
                    (option: OptionTypeBase) => option.label == event.message.data_type,
                ) as CustomPropertyDataType;
                setDataType(dataType ? dataType.value : null);

                setRowId(event.message.id);
                setEditName(event.message.name);
                setEditDescription(event.message.description);

                const type = typeResults.find((option: OptionTypeBase) => option.label == event.message.type);
                setEditPropertyType(type ?? null);

                if (event.message.options != '') {
                    setHasOptions(true);
                } else {
                    setHasOptions(dataType?.options ?? false);
                }
                setEditOptions(event.message.options ?? '');

                if (userPermissions.clientGroupsCustomProperties === ModulePermissions.ENABLED) {
                    const clientGroupsOptions: OptionTypeBase[] = data[2];
                    const client_group = clientGroupsOptions.find(
                        (option: OptionTypeBase) => option.value == event.message.client_group_id,
                    );
                    setEditClientGroup(client_group ?? null);
                } else {
                    setEditClientGroup(null);
                }

                setEditSortOrder(event.message.sort_order.toString());
                setShowInForms(event.message.available_in_forms);
                setShowInViews(event.message.show_in_view);
                setIsLoaded(true);
                setShown(true);
            });
        });
    }, [shown]);

    const collectDataTypeDropdownOptions = async () => {
        // Do the API collect on load
        // Update this function to iterate over a list in memory
        const query = new SearchQuery(1, 100);
        return customPropertiesDataTypeRepository
            .search(query)
            .then((results: any) => {
                setDropdownOptions(results.results);
                return results.results;
            })
            .catch((err: any) => {
                return null;
            });
    };

    const dropdownMapLambda = (element: CustomPropertyDataType | OptionTypeBase) => {
        return {
            value: element.value,
            label: element.label,
        };
    };

    const filterDataTypeDropdownOptions = async (filter: string) => {
        if (filter.length == 0) {
            return dropdownOptions.map(dropdownMapLambda);
        } else {
            return dropdownOptions
                .filter((option: CustomPropertyDataType) => option.label?.toLowerCase().includes(filter.toLowerCase()))
                .map(dropdownMapLambda);
        }
    };

    const getPropertyTypes = async (filter: string) => {
        const query = new SearchQuery(1, 100);
        return customPropertiesTypeRepository
            .search(query)
            .then((results: any) => {
                return filter.length == 0
                    ? results.results
                    : results.results
                          .filter((option: OptionTypeBase) =>
                              option.label?.toLowerCase().includes(filter.toLowerCase()),
                          )
                          .map(dropdownMapLambda);
            })
            .catch((err: any) => {
                return null;
            });
    };

    const getClientGroups = async (filter: string) => {
        const query = new SearchQuery(1, 1000);
        if (filter.length > 0) {
            query.setFilter(filter);
        }
        return customPropertiesClientGroupsRepository
            .search(query)
            .then((results: any) => {
                return results.results.map((result: ClientGroup) => {
                    return { value: result.id, label: result.name };
                });
            })
            .catch((err: any) => {
                return null;
            });
    };

    const validate = async (): Promise<boolean> => {
        const nameValid = !!editName && isValidString(editName);
        setEditNameValid(nameValid);

        let optionsValid = true;
        if (hasOptions) {
            const tokenArray = editOptions.split('\n');
            tokenArray.forEach((element) => {
                optionsValid = optionsValid && element.trim().length > 0;
            });
        }
        setEditOptionsValid(optionsValid);

        const sortOrderValid = !!editSortOrder;
        setEditSortOrderValid(sortOrderValid);

        return nameValid && optionsValid && sortOrderValid;
    };

    const handleEditRow = async (): Promise<boolean> => {
        const CustomProperty: CustomProperty = {
            id: rowId,
            name: editName,
            description: editDescription,
            type: editPropertyType!.value,
            data_type: dataType as string,
            sort_order: editSortOrder,
            available_in_forms: editShowInForms,
            show_in_view: editShowInViews,
            archived: archived,
            options: editOptions,
            client_group_info: editClientGroup ? { id: editClientGroup.value, name: editClientGroup.label } : null,
        };

        return customPropertiesRepository
            .update(CustomProperty)
            .then((results: any) => {
                props.onSuccess(results.data);
                setIsLoaded(true);
                return Promise.resolve(true);
            })
            .catch((err) => {
                setIsLoaded(true);
                showBanner({
                    message: 'Failed to edit custom property - ' + (err?.message ?? err),
                    level: LogLevel.ERROR,
                });
                return Promise.resolve(false);
            });
    };

    const onSubmitForm = async (e: FormEvent) => {
        setIsSubmitting(true);
        e.preventDefault();
        setIsLoaded(false);
        if ((await validate()) && (await handleEditRow())) setShown(false);
        setIsSubmitting(false);
    };

    const onSubmitFormWithDataGridReload = async (e: FormEvent) => {
        await onSubmitForm(e);
        props.reloadDataGrid();
    };

    return (
        <>
            {isEdit && (
                <Sisp
                    isOpen={shown}
                    onSubmit={handleEditRow}
                    onCancel={() => {
                        setShown(false);
                    }}
                    validate={validate}
                >
                    <h4>Edit Custom Property</h4>
                    {!isLoaded && (
                        <div style={{ position: 'relative', alignItems: 'center' }}>
                            <Card
                                className="loading-spinner-container filter-loading-spinner"
                                style={{ background: '#f9f9f9' }}
                            >
                                <Spinner animation="border" role="status" />
                            </Card>
                        </div>
                    )}
                    {isLoaded && (
                        <Form onSubmit={onSubmitForm}>
                            <Form.Group>
                                <Form.Label>Type</Form.Label>
                                <AsyncDropDown
                                    value={editPropertyType}
                                    isDisabled={true}
                                    isInvalid={false}
                                    onChange={() => {}}
                                    loadOptions={getPropertyTypes}
                                    menuPortalTarget={document.body}
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>
                                    Name <span className="required-field-marker">*</span>
                                </Form.Label>
                                <Form.Control
                                    autoComplete="off"
                                    type="text"
                                    isInvalid={!editNameValid}
                                    value={editName || ''}
                                    onChange={(event) => {
                                        setEditName(event.target.value);
                                        setEditNameValid(true);
                                    }}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {!editNameValid && editName == '' && 'This field is required.'}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Description</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows={3}
                                    autoComplete="off"
                                    type="text"
                                    value={editDescription || ''}
                                    onChange={(event) => {
                                        setEditDescription(event.target.value);
                                    }}
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Data Type</Form.Label>
                                <AsyncDropDown
                                    value={dropdownOptions.find((option: OptionTypeBase) => option.value == dataType)}
                                    isDisabled={true}
                                    isInvalid={false}
                                    onChange={() => {}}
                                    loadOptions={filterDataTypeDropdownOptions}
                                    isClearable={false}
                                    menuPortalTarget={document.body}
                                />
                            </Form.Group>
                            {hasOptions && (
                                <Form.Group>
                                    <Form.Label>
                                        Options <span className="required-field-marker">*</span>
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        as="textarea"
                                        rows={5}
                                        value={editOptions || ''}
                                        onChange={(event) => {
                                            const values = event.target.value;
                                            setEditOptions(values);
                                        }}
                                    />
                                    <span className="help-block">One option per line.</span>
                                    <Form.Control.Feedback type="invalid">
                                        {!editOptionsValid &&
                                            'This field is required. Please ensure there are no empty lines.'}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            )}
                            {userPermissions.clientGroupsCustomProperties === ModulePermissions.ENABLED && (
                                <Form.Group>
                                    <Form.Label>Group</Form.Label>
                                    <AsyncDropDown
                                        value={editClientGroup}
                                        isInvalid={false}
                                        onChange={(option: OptionTypeBase) => {
                                            setEditClientGroup(option);
                                        }}
                                        loadOptions={getClientGroups}
                                        isClearable={true}
                                        menuPortalTarget={document.body}
                                    />
                                </Form.Group>
                            )}
                            <Form.Group>
                                <Form.Label>
                                    Sort Order <span className="required-field-marker">*</span>
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    isInvalid={!editSortOrderValid}
                                    value={editSortOrder || ''}
                                    onChange={(event) => {
                                        const valueNumbersOnly = event.target.value.replace(/\D/g, '');
                                        setEditSortOrder(valueNumbersOnly);
                                        setEditSortOrderValid(true);
                                    }}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {!editSortOrderValid && editSortOrder == '' && 'This field is required.'}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group>
                                <Form.Check
                                    id="show_in_forms"
                                    name="show_in_forms"
                                    type="checkbox"
                                    label="Show in Forms"
                                    custom
                                    checked={editShowInForms}
                                    onChange={() => setShowInForms(!editShowInForms)}
                                />
                                {editPropertyType && (
                                    <Form.Check
                                        id="show_in_view_pages"
                                        name="show_in_view_pages"
                                        type="checkbox"
                                        label={`Show in ${editPropertyType.label} View Pages`}
                                        custom
                                        checked={editShowInViews}
                                        onChange={() => setShowInViews(!editShowInViews)}
                                    />
                                )}
                            </Form.Group>
                        </Form>
                    )}
                </Sisp>
            )}
            {isArchive && (
                <Modal
                    className="custom-property-archive-modal"
                    title={'Archive Custom Property'}
                    fullScreen={false}
                    close={() => setShown(false)}
                    isOpen={shown}
                    type={ModalType.INFO}
                    footerOverride={
                        <>
                            <Button
                                variant="default"
                                onClick={() => {
                                    setShown(false);
                                }}
                            >
                                Cancel
                            </Button>
                            <PendingButton
                                variant="primary"
                                onClick={onSubmitFormWithDataGridReload}
                                pending={isSubmitting}
                            >
                                Archive
                            </PendingButton>
                        </>
                    }
                >
                    If you archive this custom property it will not be displayed any more and it will be automatically
                    removed from any forms that use it. However, you can restore it at any time.
                </Modal>
            )}
            {isRestore && (
                <Modal
                    className="custom-property-archive-modal"
                    title={'Restore Custom Property'}
                    fullScreen={false}
                    close={() => setShown(false)}
                    isOpen={shown}
                    type={ModalType.INFO}
                    footerOverride={
                        <>
                            <Button
                                variant="default"
                                onClick={() => {
                                    setShown(false);
                                }}
                            >
                                Cancel
                            </Button>
                            <PendingButton
                                variant="primary"
                                onClick={onSubmitFormWithDataGridReload}
                                pending={isSubmitting}
                            >
                                Restore
                            </PendingButton>
                        </>
                    }
                >
                    Are you sure you want to restore this custom property?
                </Modal>
            )}
        </>
    );
};

export default CustomPropertiesEditSisp;
