import urlJoin from 'url-join';
import ClientGroup from '../Models/ClientGroup';
import DataGridRequest from './DataGridRequest';

export class ClientGroupsRequest extends DataGridRequest<ClientGroup> {
    static baseUrl = '/api/v1/client_groups';

    Url: string;

    constructor(type: string) {
        super();
        this.Url = urlJoin(ClientGroupsRequest.baseUrl, type);
    }
}
